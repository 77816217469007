import { ContextType } from '@generated/graphql'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Template, TemplateMinified, TemplatesState } from './types'

const initialState: TemplatesState = {
  list: [],
  allList: [],
  loading: false,
  allListLoading: false,
}

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setTemplates(state, action: PayloadAction<Template[]>) {
      const mergedTemplates = action.payload.reduce((acc, template) => {
        if (template.network) {
          const globalTemplate = action.payload.find(
            t =>
              t.type === template.type &&
              t.subtype === template.subtype &&
              !t.network,
          )
          if (globalTemplate) {
            const mergedTemplate = {
              ...template,
              fields: [
                ...globalTemplate.fields.filter(
                  f => f.context === ContextType.Global,
                ),
                ...template.fields,
              ],
            }
            acc.push(mergedTemplate)
            return acc
          }
        }

        acc.push(template)

        return acc
      }, [])

      state.list = mergedTemplates
      state.loading = false
    },
    setTemplatesLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setAllTemplates(state, action: PayloadAction<TemplateMinified[]>) {
      state.allList = action.payload
      state.allListLoading = false
    },
    setAllTemplatesLoading(state, action: PayloadAction<boolean>) {
      state.allListLoading = action.payload
    },
  },
})

export default templatesSlice.reducer

export const {
  setTemplates,
  setTemplatesLoading,
  setAllTemplates,
  setAllTemplatesLoading,
} = templatesSlice.actions
