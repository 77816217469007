import LiveUsers from '@components/LayoutComponents/TopBar/LiveUsers'
import NetworkSelect from '@components/LayoutComponents/TopBar/NetworkSelect'
// import NotificationsMenu from '@components/LayoutComponents/TopBar/NotificationsMenu'
import ProfileMenu from '@components/LayoutComponents/TopBar/ProfileMenu'
import PurgeCache from '@components/LayoutComponents/TopBar/PurgeCache'
import SiteSelect from '@components/LayoutComponents/TopBar/SiteSelect'
import VerticalSelect from '@components/LayoutComponents/TopBar/VerticalSelect'
import { usePermissions } from '@components/Permissions'
import { APP_MENU_SELECTED_KEYS } from '@constants/storageHelper'
import { menuTopDataSelector } from '@store/slices/menu/selectors'
import { useAppSelector } from '@store/typedHooks'
import { Menu } from 'antd'
import find from 'lodash/find'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import store from 'store'

import { flattenItems, generateMenuItems } from '../helpers'
import { generateItem } from './helpers'
import styles from './style.module.scss'

const MenuTop = () => {
  const menuData = useAppSelector(menuTopDataSelector)

  const [selectedKeys, setSelectedKeys] = useState(
    store.get(APP_MENU_SELECTED_KEYS) || [],
  )

  const { allowed } = usePermissions()

  const location = useLocation()

  useEffect(() => {
    const selectedItem = find(flattenItems(menuData, 'children'), [
      'url',
      location.pathname,
    ])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }, [location.pathname, menuData])

  const handleClick = e => {
    store.set(APP_MENU_SELECTED_KEYS, [e.key])
    setSelectedKeys([e.key])
  }

  const menuChildren = generateMenuItems(allowed, menuData, generateItem)

  return (
    <div className={styles.menuTopWrapper}>
      <MenuTopItem>
        <NetworkSelect />
      </MenuTopItem>
      <MenuTopItem>
        <VerticalSelect />
      </MenuTopItem>
      <MenuTopItem>
        <SiteSelect />
      </MenuTopItem>
      <MenuTopItem>
        <Menu
          theme="dark"
          onClick={handleClick}
          selectedKeys={selectedKeys}
          mode="horizontal"
          items={menuChildren}
        />
      </MenuTopItem>
      <div className={styles.freeSpaceEater} />
      <MenuTopItem>
        <PurgeCache />
      </MenuTopItem>
      <MenuTopItem>
        <ProfileMenu />
      </MenuTopItem>
      {/*<MenuTopItem>*/}
      {/*  <NotificationsMenu />*/}
      {/*</MenuTopItem>*/}
      <MenuTopItem>
        <LiveUsers />
      </MenuTopItem>
    </div>
  )
}

const MenuTopItem = ({ children }) => {
  return <div className={styles.menuTopItem}>{children}</div>
}

export default MenuTop
