import { useAuth0 } from '@auth0/auth0-react'
import { useSitesSlimLazyQuery } from '@generated/graphql'
import { setSites, setSitesLoading } from '@store/slices/sites'
import { getSitesWhere } from '@store/slices/sites/helpers'
import { currentVerticalSelector } from '@store/slices/verticals/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { useCallback, useEffect } from 'react'

export function useFetchSites(refetchOnly: boolean = false) {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const currentVertical = useAppSelector(currentVerticalSelector)
  const verticalsLoading = useAppSelector(state => state.verticals.loading)
  const verticalId = currentVertical?.id

  const [querySites, { refetch }] = useSitesSlimLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ sites }) => {
      dispatch(setSites(sites))
    },
    onError: error => {
      console.error('Unable to load sites', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated || !verticalId || verticalsLoading || refetchOnly) {
      return
    }

    dispatch(setSitesLoading(true))
    querySites({
      variables: {
        where: getSitesWhere(verticalId),
      },
    })
  }, [
    dispatch,
    verticalId,
    isAuthenticated,
    querySites,
    verticalsLoading,
    refetchOnly,
  ])

  const fetchSites = useCallback(() => {
    dispatch(setSitesLoading(true))
    refetch({
      where: getSitesWhere(verticalId),
    })
  }, [dispatch, refetch, verticalId])

  if (refetchOnly) {
    return fetchSites
  }
}
