import { useConnectLiveUsers } from '@store/hooks/useConnectLiveUsers'
import { useCreateMenu } from '@store/hooks/useCreateMenu'
import { useFetchAllTaxonomyItems } from '@store/hooks/useFetchAllTaxonomyItems'
import { useFetchAllTemplates } from '@store/hooks/useFetchAllTemplates'
import { useFetchCounts } from '@store/hooks/useFetchCounts'
import { useFetchLanguages } from '@store/hooks/useFetchLanguages'
import { useFetchNetworks } from '@store/hooks/useFetchNetworks'
import { useFetchSites } from '@store/hooks/useFetchSites'
import { useFetchVariables } from '@store/hooks/useFetchVariables'
import { useFetchVerticals } from '@store/hooks/useFetchVerticals'
import React, { FC, ReactNode } from 'react'

const InitialStoreComponent: FC<{ children: ReactNode }> = ({ children }) => {
  useFetchNetworks()
  useCreateMenu()
  useConnectLiveUsers()
  useFetchVerticals()
  useFetchSites()
  useFetchAllTemplates()
  useFetchCounts()
  useFetchLanguages()

  useFetchAllTaxonomyItems()
  useFetchVariables()

  return <>{children}</>
}

export default InitialStoreComponent
