import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../index'

const liveUsersList = (state: RootState) => state.liveUsers.list

export const liveUsersSelector = createSelector(
  [liveUsersList],
  liveUsersList => liveUsersList,
)
