import { RocketOutlined } from '@ant-design/icons'
import { LinkWithQuery } from '@components/LinkWithQuery'
import { useCheckPermissions } from '@components/Permissions/useCheckPermissions'
import { DEFAULT_LANGUAGE } from '@constants'
import { NAME_KEY } from '@constants/fieldKeys'
import { getStringFromContent } from '@modules/content'
import {
  currentNetworkIdSelector,
  currentNetworkSelector,
  networksListSelector,
  networksSelectorConfigSelector,
} from '@store/slices/networks/selectors'
import { useAppSelector } from '@store/typedHooks'
import { permissions } from '@wdnsolutions/auth-helpers'
import { Dropdown } from 'antd'
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import useTopSelectorChange from '../hooks/useChangeTopSelector'
import { IMenuItem } from '../SiteSelect'
import dropdownStyles from '../style.module.scss'
import styles from './style.module.scss'

const NetworkSelect = () => {
  const selectorConfig = useAppSelector(networksSelectorConfigSelector)
  const currentNetwork = useAppSelector(currentNetworkSelector)

  const menu = useRenderMenu()

  return (
    <Dropdown
      overlayClassName={dropdownStyles.dropdown}
      disabled={selectorConfig.disabled}
      menu={menu}
      trigger={['click']}
      placement="bottomLeft">
      <div
        className={classNames(
          dropdownStyles.dropdownButton,
          selectorConfig.disabled && dropdownStyles.disabled,
        )}>
        <i className={`${styles.icon} icmn-tree`} />
        {renderNetwork(currentNetwork)}
      </div>
    </Dropdown>
  )
}

export default memo(NetworkSelect)

function renderNetwork(currentNetwork) {
  if (!currentNetwork) {
    return (
      <span className="font-weight-semibold text-uppercase text-white">
        <FormattedMessage id="topBar.allNetworks" />
      </span>
    )
  }

  return (
    <span className="font-weight-semibold text-uppercase text-white">
      {getStringFromContent(NAME_KEY, {
        content: currentNetwork?.content,
        language: DEFAULT_LANGUAGE.languageTag,
      }) || currentNetwork?.id}
    </span>
  )
}

function useRenderMenu() {
  const networks = useAppSelector(networksListSelector)
  const currentNetworkId = useAppSelector(currentNetworkIdSelector)
  const networkSelectorConfig = useAppSelector(networksSelectorConfigSelector)
  const { onTopSelectorChange } = useTopSelectorChange('network')

  const isAddingNetworkAllowed = useCheckPermissions([
    permissions.network.create,
  ])

  const menuItems = useMemo(() => {
    const menuItems: IMenuItem[] = [
      {
        key: 'all',
        label: 'All networks',
        disabled: networkSelectorConfig?.autoSet,
      },
      { key: 'divider1', type: 'divider' },
    ]

    networks.forEach(network => {
      menuItems.push({
        key: network.id,
        label:
          getStringFromContent(NAME_KEY, {
            content: network?.content,
            language: DEFAULT_LANGUAGE.languageTag,
          }) || network.id,
      })
    })

    if (networks.length) {
      menuItems.push({ key: 'divider2', type: 'divider' })
    }

    if (isAddingNetworkAllowed) {
      menuItems.push({
        key: 'add',
        label: (
          <LinkWithQuery to="/networks/add">
            <RocketOutlined className="mr-2" /> Add network
          </LinkWithQuery>
        ),
      })
    }

    return menuItems
  }, [isAddingNetworkAllowed, networks, networkSelectorConfig])

  return {
    selectable: false,
    selectedKeys: [currentNetworkId || 'all'],
    onClick: onTopSelectorChange,
    items: menuItems,
  }
}
