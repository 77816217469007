import { useAuth0 } from '@auth0/auth0-react'
import { useProvidersAndTaxonomiesCountsLazyQuery } from '@generated/graphql'
import { resetCounts, setCounts } from '@store/slices/counts'
import { createCountsWhere } from '@store/slices/counts/helpers'
import { countsSelector } from '@store/slices/counts/selectors'
import {
  currentNetworkIdSelector,
  networksLoadingSelector,
} from '@store/slices/networks/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { useCallback, useEffect, useRef } from 'react'

export function useFetchCounts(refetchOnly: boolean = false) {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const networkLoading = useAppSelector(networksLoadingSelector)
  const networkId = useAppSelector(currentNetworkIdSelector)
  const counts = useAppSelector(countsSelector)

  const isFirstCall = useRef(true)

  const [queryCounts, { refetch }] = useProvidersAndTaxonomiesCountsLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ providersCount, taxonomyItemsCount }) => {
      if (
        counts.providersCount !== providersCount ||
        counts.taxonomyItemsCount !== taxonomyItemsCount
      ) {
        dispatch(setCounts({ providersCount, taxonomyItemsCount }))
      }
    },
    onError: error => {
      console.error('Unable to load counts', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated || networkLoading || refetchOnly) {
      return
    }

    if (!networkId) {
      dispatch(resetCounts())
      return
    }

    const where = createCountsWhere(networkId)

    if (isFirstCall.current) {
      // TODO: find a better approach to increment a queries
      isFirstCall.current = false
      new Promise(() => {
        setTimeout(() => {
          queryCounts({
            variables: {
              providerWhere: where,
              taxonomyItemWhere: where,
            },
          })
        }, 8000)
      })
    } else {
      queryCounts({
        variables: {
          providerWhere: where,
          taxonomyItemWhere: where,
        },
      })
    }
  }, [
    isAuthenticated,
    queryCounts,
    networkLoading,
    dispatch,
    refetchOnly,
    networkId,
  ])

  const fetchCounts = useCallback(() => {
    if (!networkId) {
      return
    }

    const where = createCountsWhere(networkId)
    refetch({
      providerWhere: where,
      taxonomyItemWhere: where,
    })
  }, [networkId, refetch])

  if (refetchOnly) {
    return fetchCounts
  }
}
