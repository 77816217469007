import { DEFAULT_SELECTOR_STORE_PARAM_KEY } from '@constants'
import { SelectorConfigBeginState } from '@pages/router/components/TopSelectorSet/hooks/useTopSelector'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Vertical, VerticalsState } from './types'

const initialState: VerticalsState = {
  list: [],
  currentVerticalId: DEFAULT_SELECTOR_STORE_PARAM_KEY,
  loading: false,
  selectorConfig: {},
}

const verticalsSlice = createSlice({
  name: 'verticals',
  initialState,
  reducers: {
    setVerticals(state, action: PayloadAction<Vertical[]>) {
      state.list = action.payload
      state.loading = false
    },
    resetVerticals(state) {
      state.list = initialState.list
    },
    setCurrentVertical(state, action: PayloadAction<string>) {
      state.currentVerticalId = action.payload
    },
    resetCurrentVertical(state) {
      state.currentVerticalId = initialState.currentVerticalId
    },
    setVerticalsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setVerticalsSelectorConfig(
      state,
      action: PayloadAction<SelectorConfigBeginState>,
    ) {
      state.selectorConfig = action.payload
    },
  },
})

export default verticalsSlice.reducer

export const {
  setVerticals,
  resetVerticals,
  setCurrentVertical,
  resetCurrentVertical,
  setVerticalsLoading,
  setVerticalsSelectorConfig,
} = verticalsSlice.actions
