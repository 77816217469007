import { LinkWithQuery } from '@components/LinkWithQuery'
import React from 'react'

import MenuItemLabel from './components/MenuItemLabel'
import styles from './style.module.scss'

export { renderDivider, renderMenuItem, renderMenuLink, renderSubMenu }

function renderSubMenu(item, children, isMenuCollapsed) {
  return {
    key: item.key,
    children,
    label: item.renderLabel ? (
      item.renderLabel(item, isMenuCollapsed)
    ) : (
      <MenuItemLabel key={item.key} icon={item.icon} title={item.title} />
    ),
  }
}

function renderDivider({ key }) {
  return {
    key,
    type: 'divider',
  }
}

function renderMenuLink({ key, title, url, icon, disabled, target }) {
  return {
    key,
    disabled,
    label: (
      <>
        {target ? (
          <a href={url} target={target} rel="noopener noreferrer">
            <span className={`${icon || ''} ${styles.icon}`} />
            <span className={styles.title}>{title}</span>
          </a>
        ) : (
          <LinkWithQuery to={url}>
            <span className={`${icon || ''} ${styles.icon}`} />
            <span className={styles.title}>{title}</span>
          </LinkWithQuery>
        )}
      </>
    ),
  }
}

function renderMenuItem({ key, title, icon, disabled }) {
  return {
    key,
    disabled,
    label: (
      <>
        <span className={`${icon || ''} ${styles.icon}`} />
        <span className={styles.title}>{title}</span>
      </>
    ),
  }
}
