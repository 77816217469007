export default {
  edit: "site:edit",
  create: "site:create",
  delete: "site:delete",
  view: "site:view",
  deploy: "site:deploy",
  blob: {
    generate: "site:blob:generate",
  },
};
