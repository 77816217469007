import FeedbackResult from '@components/LayoutComponents/FeedbackResult'
import React, { PureComponent, ReactNode } from 'react'

import styles from './styles.module.scss'
import { IErrorBoundary, IErrorBoundaryState } from './types'

export default class ErrorBoundary extends PureComponent<
  IErrorBoundary,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundary) {
    super(props)

    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }): void {
    // Catch errors in any components below and re-render with error message
    this.setState({ error, errorInfo })
  }

  render(): ReactNode {
    const { children } = this.props
    const { errorInfo, error } = this.state

    return errorInfo ? (
      <div data-testid="error-view">
        <FeedbackResult
          title="Something went wrong. Please contact the developer of this app if the
          issue persists"
          subTitle={
            <>
              {error && error.toString()}
              <details className={styles.errorMessage}>
                <br />
                {errorInfo.componentStack}
              </details>
            </>
          }
        />
      </div>
    ) : (
      children || null
    )
  }
}
