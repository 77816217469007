import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Counts, CountsState } from './types'

const initialState: CountsState = {
  providersCount: 0,
  taxonomyItemsCount: 0,
  loading: true,
}

const countsSlice = createSlice({
  name: 'counts',
  initialState,
  reducers: {
    setCounts(state, action: PayloadAction<Counts>) {
      state.providersCount = action.payload.providersCount
      state.taxonomyItemsCount = action.payload.taxonomyItemsCount
      state.loading = false
    },
    setCountsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    resetCounts(state) {
      state.providersCount = initialState.providersCount
      state.taxonomyItemsCount = initialState.taxonomyItemsCount
      state.loading = initialState.loading
    },
  },
})

export default countsSlice.reducer

export const { setCounts, setCountsLoading, resetCounts } = countsSlice.actions
