import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { removeItemsByKey } from './helpers'
import { Notification, NotificationsState } from './types'

const initialState: NotificationsState = {
  list: [],
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNotification(state, action: PayloadAction<Notification>) {
      if (action.payload) {
        state.list = [
          ...removeItemsByKey(state.list, 'id', action.payload.id),
          action.payload,
        ]
      }
    },
    removeNotification(state, action: PayloadAction<string>) {
      if (action.payload) {
        state.list = removeItemsByKey(state.list, 'id', action.payload)
      }
    },
  },
})

export default notificationsSlice.reducer

export const { pushNotification, removeNotification } =
  notificationsSlice.actions
