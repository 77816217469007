import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

const Loader = ({ spinning = true, fullScreen = false }) => (
  <div
    className={classNames(
      styles.spinner,
      spinning && styles.spinning,
      fullScreen && styles.fullScreen,
    )}
  />
)

export default Loader
