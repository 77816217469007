import { configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'

import persistedReducer from './persistedReducer'

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'menu/setLeftMenuData',
          'menu/setTopMenuData',
          'editor/setActiveEditor',
          'editor/setIsUnsavedChangesModal',
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
        ignoredPaths: [
          'menu',
          'editor.activeEditorInstance',
          'editor.activeEditorSelectionRange',
          'editor.unsavedChangesDiscardAction',
        ],
      },
      immutableCheck: false,
    }),
})

export const persistor = persistStore(store)

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
