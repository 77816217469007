import { DynamicFormModes } from '@components/DynamicForm/types'
import MenuItemLabelWithCount from '@components/LayoutComponents/Menu/MenuLeft/components/MenuItemLabelWithCount'
import { DEFAULT_SELECTOR_SEARCH_PARAM_KEY } from '@constants'
import { TITLE_KEY } from '@constants/fieldKeys'
import { TemplateType } from '@generated/graphql'
import { getStringFromContent } from '@modules/content'
import { getTemplate, getTemplates } from '@store/slices/templates/helpers'
import { permissions } from '@wdnsolutions/auth-helpers'
import constant from 'lodash/constant'
import * as pluralize from 'pluralize'
import React, { lazy } from 'react'

export const routes = [
  {
    path: 'index',
    component: {
      Component: lazy(() => import('@pages/index/index')),
    },
  },
  {
    path: 'user',
    children: [
      {
        path: 'login',
        component: { Component: lazy(() => import('@pages/user/login')) },
      },
      {
        path: 'forgot',
        // @ts-ignore
        component: { Component: lazy(() => import('@pages/user/forgot')) },
      },
    ],
  },
  // {
  //   path: 'dashboards',
  //   menu: {
  //     left: [
  //       {
  //         title: constant('Performance'),
  //         permissions: [permissions.status.view],
  //         icon: 'icmn icmn-stats-dots',
  //       },
  //     ],
  //     top: [
  //       {
  //         title: constant('Performance'),
  //         permissions: [permissions.status.view],
  //         icon: 'icmn icmn-stats-dots',
  //       },
  //     ],
  //   },
  //   component: {
  //     Component: lazy(() => import('@pages/dashboards/index')),
  //     params: {
  //       topSelector: {
  //         network: { disabled: true },
  //         vertical: { disabled: true, selected: 'all' },
  //         site: {
  //           selected: 'all',
  //           disabled: true,
  //         },
  //       },
  //     },
  //   },
  // },
  {
    path: 'verticals',
    menu: {
      left: [
        {
          title: constant('Verticals'),
          permissions: [permissions.vertical.view],
          icon: 'icmn icmn-stack',
        },
      ],
    },
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('List'),
              permissions: [permissions.vertical.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/vertical/list')),
          params: {
            types: [TemplateType.Vertical],
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'add',
        component: {
          Component: lazy(() => import('@pages/vertical/crud')),
          params: {
            types: [TemplateType.Vertical],
            mode: DynamicFormModes.Add,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        component: {
          Component: lazy(() => import('@pages/vertical/crud')),
          params: {
            types: [TemplateType.Vertical],
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false },
              site: { disabled: false },
            },
          },
        },
      },
      {
        path: 'redirects',
        menu: {
          left: [
            {
              title: constant('Redirect Management'),
              permissions: [permissions.vertical.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/vertical/redirects')),
          params: {
            types: [TemplateType.Vertical],
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                disabled: true,
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
              },
            },
          },
        },
      },
    ],
  },
  {
    path: 'sites',
    menu: {
      left: [
        {
          title: getTitleForType('Sites', TemplateType.Site),
          permissions: [permissions.site.view],
          icon: 'icmn icmn-sphere',
        },
      ],
    },
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('List'),
              permissions: [permissions.site.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/site/list')),
          params: {
            types: [TemplateType.Site],
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'status',
        menu: {
          left: [
            {
              title: constant('Status'),
              permissions: [permissions.status.view],
            },
          ],
          top: [
            {
              title: constant('Status'),
              permissions: [permissions.status.view],
              icon: 'icmn icmn-meter',
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/status/index')),
          params: {
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'add',
        menu: {
          left: [
            {
              title: constant('Add'),
              permissions: [permissions.site.create],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/site/crud')),
          params: {
            mode: DynamicFormModes.Add,
            topSelector: {
              types: [TemplateType.Site],
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        component: {
          Component: lazy(() => import('@pages/site/crud')),
          params: {
            mode: DynamicFormModes.Edit,
            topSelector: {
              types: [TemplateType.Site],
              network: { disabled: false, autoSet: true },
              vertical: { disabled: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'view/:id',
        component: {
          Component: lazy(() => import('@pages/site/crud')),
          params: {
            types: [TemplateType.Site],
            mode: DynamicFormModes.View,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: true },
              site: { disabled: false },
            },
          },
        },
      },
    ],
  },
  {
    path: 'providers',
    menu: {
      left: [
        {
          title: getTitleForType('Providers', TemplateType.Provider),
          icon: 'icmn icmn-briefcase',
          renderLabel: (item, isMenuCollapsed) => (
            <MenuItemLabelWithCount
              countName="providersCount"
              icon={item.icon}
              title={item.title}
              isMenuCollapsed={isMenuCollapsed}
            />
          ),
        },
      ],
    },
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('List'),
              permissions: [permissions.provider.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/provider/list')),
          params: {
            types: [TemplateType.Provider],
            topSelector: {
              network: { disabled: false },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'pools/list',
        menu: {
          left: [
            {
              title: constant('Pools'),
              permissions: [permissions.provider.edit],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/provider/pools/list')),
          params: {
            types: [TemplateType.Provider],
            topSelector: {
              network: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'pools/add',
        component: {
          Component: lazy(() => import('@pages/provider/pools/crud')),
          params: {
            types: [TemplateType.Provider],
            topSelector: {
              network: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'pools/edit/:id',
        component: {
          Component: lazy(() => import('@pages/provider/pools/crud')),
          params: {
            types: [TemplateType.Provider],
            topSelector: {
              network: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'status',
        menu: {
          left: [
            {
              title: constant('Status'),
              permissions: [permissions.provider.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/provider/status')),
          params: {
            types: [TemplateType.Provider],
            topSelector: {
              network: { disabled: false },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'overview',
        menu: {
          left: [
            {
              title: constant('Overview'),
              permissions: [permissions.provider.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/provider/overview')),
          params: {
            topSelector: {
              types: [TemplateType.Provider],
              network: { disabled: false },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'add',
        menu: {
          left: [
            {
              title: constant('Add'),
              permissions: [permissions.provider.create],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/provider/crud')),
          params: {
            types: [
              TemplateType.Provider,
              TemplateType.Providertaxonomyconnection,
            ],
            mode: DynamicFormModes.Add,
            topSelector: {
              network: { disabled: false },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'view/:id',
        component: {
          Component: lazy(() => import('@pages/provider/crud')),
          params: {
            types: [
              TemplateType.Provider,
              TemplateType.Providertaxonomyconnection,
            ],
            mode: DynamicFormModes.View,
            topSelector: {
              network: { disabled: false },
              vertical: { disabled: false },
              site: { disabled: false },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        component: {
          Component: lazy(() => import('@pages/provider/crud')),
          params: {
            types: [
              TemplateType.Provider,
              TemplateType.Providertaxonomyconnection,
            ],
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: { disabled: false },
              vertical: { disabled: false, autoSet: true },
              site: { disabled: false },
            },
          },
        },
      },
    ],
  },
  {
    path: 'offers',
    menu: {
      left: [
        {
          title: constant('Offers'),
          icon: 'icmn icmn-coin-dollar',
        },
      ],
    },
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('List'),
              permissions: [permissions.provider.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/offers/list')),
          params: {
            topSelector: {
              network: { disabled: false },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'templates',
        menu: {
          left: [
            {
              title: constant('Templates'),
              permissions: [permissions.provider.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/offers/templates/list')),
          params: {
            topSelector: {
              network: { disabled: false },
              vertical: {
                disabled: false,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'templates/add',
        component: {
          Component: lazy(() => import('@pages/offers/templates/crud')),
          params: {
            mode: DynamicFormModes.Add,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false },
              site: {
                disabled: true,
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
              },
            },
          },
        },
      },
      {
        path: 'templates/edit/:id',
        component: {
          Component: lazy(() => import('@pages/offers/templates/crud')),
          params: {
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false },
              site: {
                disabled: true,
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
              },
            },
          },
        },
      },
    ],
  },
  {
    path: 'taxonomies',
    menu: {
      left: [
        {
          title: getTitleForType('Taxonomies', TemplateType.Taxonomy),
          icon: 'icmn icmn-price-tags',
          renderLabel: (item, isMenuCollapsed) => (
            <MenuItemLabelWithCount
              countName="taxonomyItemsCount"
              icon={item.icon}
              title={item.title}
              isMenuCollapsed={isMenuCollapsed}
            />
          ),
        },
      ],
    },
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('List'),
              permissions: [permissions.taxonomy.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/taxonomy/list')),
          params: {
            types: [TemplateType.Taxonomy],
            topSelector: {
              network: { disabled: false },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'pools/list',
        menu: {
          left: [
            {
              title: constant('Pools'),
              permissions: [permissions.taxonomy.edit],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/taxonomy/pools/list')),
          params: {
            types: [TemplateType.Taxonomy],
            topSelector: {
              network: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'pools/add',
        component: {
          Component: lazy(() => import('@pages/taxonomy/pools/crud')),
          params: {
            types: [TemplateType.Taxonomy],
            topSelector: {
              network: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'pools/edit/:id',
        component: {
          Component: lazy(() => import('@pages/taxonomy/pools/crud')),
          params: {
            types: [TemplateType.Taxonomy],
            topSelector: {
              network: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'status',
        menu: {
          left: [
            {
              title: constant('Status'),
              permissions: [permissions.taxonomy.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/taxonomy/TaxonomyStatus')),
          params: {
            types: [TemplateType.Taxonomy],
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'add/:type',
        menu: {
          left: [
            {
              title: constant('Add Group'),
              url: 'add/group',
              permissions: [permissions.taxonomy.create],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/taxonomy/crud')),
          params: {
            types: [TemplateType.Taxonomy],
            mode: DynamicFormModes.Add,
            topSelector: {
              network: { disabled: false },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'add/:type',
        menu: {
          left: [
            {
              title: constant('Add Country'),
              url: 'add/country',
              permissions: [permissions.taxonomy.create],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/taxonomy/crud')),
          params: {
            types: [TemplateType.Taxonomy],
            mode: DynamicFormModes.Add,
            topSelector: {
              network: { disabled: false },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'add/:type',
        menu: {
          left: [
            {
              title: constant('Add Taxonomy'),
              url: 'add/default',
              permissions: [permissions.taxonomy.create],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/taxonomy/crud')),
          params: {
            types: [TemplateType.Taxonomy],
            mode: DynamicFormModes.Add,
            topSelector: {
              network: { disabled: false },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'view/:id',
        component: {
          Component: lazy(() => import('@pages/taxonomy/crud')),
          params: {
            types: [TemplateType.Taxonomy],
            mode: DynamicFormModes.View,
            topSelector: {
              network: { disabled: false },
              vertical: { disabled: false },
              site: { disabled: false },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        component: {
          Component: lazy(() => import('@pages/taxonomy/crud')),
          params: {
            types: [TemplateType.Taxonomy],
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: { disabled: false },
              vertical: { disabled: false, autoSet: true },
              site: { disabled: false },
            },
          },
        },
      },
    ],
  },
  {
    path: 'filterTaxonomies',
    menu: {
      left: [
        {
          title: constant('Filter taxonomies'),
          icon: 'icmn icmn-price-tags',
        },
      ],
    },
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('List'),
              permissions: [permissions.filterTaxonomy.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/filterTaxonomy/list')),
          params: {
            types: [TemplateType.Filtertaxonomyitem],
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'add',
        component: {
          Component: lazy(() => import('@pages/filterTaxonomy/crud')),
          params: {
            types: [TemplateType.Filtertaxonomyitem],
            mode: DynamicFormModes.Add,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'view/:id',
        component: {
          Component: lazy(() => import('@pages/filterTaxonomy/crud')),
          params: {
            types: [TemplateType.Filtertaxonomyitem],
            mode: DynamicFormModes.View,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false },
              site: { disabled: false },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        component: {
          Component: lazy(() => import('@pages/filterTaxonomy/crud')),
          params: {
            types: [TemplateType.Filtertaxonomyitem],
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: { disabled: false },
            },
          },
        },
      },
      {
        path: 'rules',
        children: [
          {
            path: 'list',
            menu: {
              left: [
                {
                  title: constant('Rules'),
                  permissions: [permissions.filterTaxonomyRule.view],
                },
              ],
            },
            component: {
              Component: lazy(() => import('@pages/filterTaxonomyRule/list')),
              params: {
                types: [TemplateType.Filtertaxonomyrule],
                topSelector: {
                  network: { disabled: false, autoSet: true },
                  vertical: {
                    selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                    disabled: true,
                  },
                  site: {
                    selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                    disabled: true,
                  },
                },
              },
            },
          },
          {
            path: 'add',
            component: {
              Component: lazy(() => import('@pages/filterTaxonomyRule/crud')),
              params: {
                types: [TemplateType.Filtertaxonomyrule],
                mode: DynamicFormModes.Add,
                topSelector: {
                  network: { disabled: false, autoSet: true },
                  vertical: { disabled: false, autoSet: true },
                  site: {
                    selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                    disabled: true,
                  },
                },
              },
            },
          },

          {
            path: 'view/:id',
            component: {
              Component: lazy(() => import('@pages/filterTaxonomyRule/crud')),
              params: {
                types: [TemplateType.Filtertaxonomyrule],
                mode: DynamicFormModes.View,
                topSelector: {
                  network: { disabled: false, autoSet: true },
                  vertical: { disabled: false },
                  site: { disabled: false },
                },
              },
            },
          },
          {
            path: 'edit/:id',
            component: {
              Component: lazy(() => import('@pages/filterTaxonomyRule/crud')),
              params: {
                types: [TemplateType.Filtertaxonomyrule],
                mode: DynamicFormModes.Edit,
                topSelector: {
                  network: { disabled: false, autoSet: true },
                  vertical: { disabled: false, autoSet: true },
                  site: { disabled: false },
                },
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: 'products',
    children: [
      {
        path: ':type',
        menu: {
          left: getProductsMenu,
        },
        children: [
          {
            path: 'list',
            menu: {
              left: [
                {
                  title: constant('List'),
                  permissions: [permissions.product.view],
                },
              ],
            },
            component: {
              Component: lazy(() => import('@pages/product/list')),
              params: {
                types: [TemplateType.Product],
                topSelector: {
                  network: { disabled: false, autoSet: true },
                  vertical: {
                    selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                    disabled: true,
                  },
                  site: {
                    selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                    disabled: true,
                  },
                },
              },
            },
          },
          {
            path: 'add',
            menu: {
              left: [
                {
                  title: constant('Add'),
                  permissions: [permissions.product.view],
                },
              ],
            },
            component: {
              Component: lazy(() => import('@pages/product/crud')),
              params: {
                types: [TemplateType.Product],
                mode: DynamicFormModes.Add,
                topSelector: {
                  network: { disabled: false, autoSet: true },
                  vertical: { disabled: false, autoSet: true },
                  site: {
                    selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                    disabled: true,
                  },
                },
              },
            },
          },
          {
            path: 'edit/:id',
            component: {
              Component: lazy(() => import('@pages/product/crud')),
              params: {
                types: [TemplateType.Product],
                mode: DynamicFormModes.Edit,
                topSelector: {
                  network: { disabled: false, autoSet: true },
                  vertical: { disabled: false, autoSet: true },
                  site: { disabled: false },
                },
              },
            },
          },
        ],
      },
      {
        path: 'view/:id',
        component: {
          Component: lazy(() => import('@pages/product/crud')),
          params: {
            types: [TemplateType.Product],
            mode: DynamicFormModes.View,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false },
              site: { disabled: false },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        component: {
          Component: lazy(() => import('@pages/product/crud')),
          params: {
            types: [TemplateType.Product],
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: { disabled: false },
            },
          },
        },
      },
    ],
  },
  {
    path: 'authors',
    menu: {
      left: [
        {
          title: constant('Authors'),
          permissions: [permissions.author.view],
          icon: 'icmn icmn-profile',
        },
      ],
    },
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('List'),
              permissions: [permissions.author.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/author/list')),
          params: {
            types: [TemplateType.Author],
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'add',
        menu: {
          left: [
            {
              title: constant('Add'),
              permissions: [permissions.author.create],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/author/crud')),
          params: {
            types: [TemplateType.Author],
            mode: DynamicFormModes.Add,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        component: {
          Component: lazy(() => import('@pages/author/crud')),
          params: {
            types: [TemplateType.Author],
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: { disabled: false },
            },
          },
        },
      },
    ],
  },
  {
    path: 'posts',
    menu: {
      left: [
        {
          title: getTitleForType('Posts', TemplateType.Post),
          icon: 'icmn icmn-pencil',
        },
      ],
    },
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('List'),
              permissions: [permissions.post.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/post/list')),
          params: {
            types: [TemplateType.Post],
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'add',
        menu: {
          left: [
            {
              title: constant('Add'),
              permissions: [permissions.post.create],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/post/crud')),
          params: {
            types: [TemplateType.Post],
            isPage: false,
            mode: DynamicFormModes.Add,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'schedule',
        menu: {
          left: [
            {
              title: constant('Schedule'),
              permissions: [permissions.post.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/post/schedule')),
          params: {
            types: [TemplateType.Post],
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'view/:id',
        component: {
          Component: lazy(() => import('@pages/post/crud')),
          params: {
            types: [TemplateType.Post],
            isPage: false,
            mode: DynamicFormModes.View,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: { disabled: false },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        component: {
          Component: lazy(() => import('@pages/post/crud')),
          params: {
            types: [TemplateType.Post],
            isPage: false,
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: { disabled: false },
            },
          },
        },
      },
    ],
  },
  {
    path: 'pages',
    menu: {
      left: [
        {
          title: getTitleForType('Pages', TemplateType.Page),
          permissions: [permissions.page.view],
          icon: 'icmn icmn-files-empty',
        },
      ],
    },
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('List'),
              permissions: [permissions.page.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/post/list')),
          params: {
            types: [TemplateType.Page],
            isPage: true,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'add',
        menu: {
          left: [
            {
              title: constant('Add'),
              permissions: [permissions.page.create],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/post/crud')),
          params: {
            types: [TemplateType.Page],
            isPage: true,
            mode: DynamicFormModes.Add,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'view/:id',
        component: {
          Component: lazy(() => import('@pages/post/crud')),
          params: {
            types: [TemplateType.Page],
            isPage: true,
            mode: DynamicFormModes.View,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: { disabled: false },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        component: {
          Component: lazy(() => import('@pages/post/crud')),
          params: {
            types: [TemplateType.Page],
            isPage: true,
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: { disabled: false },
            },
          },
        },
      },
    ],
  },
  {
    path: 'meta-editor',
    menu: {
      left: [
        {
          title: constant('Meta editor'),
          permissions: [permissions.stringTemplate.view],
          icon: 'icmn icmn-cool2',
        },
      ],
    },
    component: {
      Component: lazy(() => import('@pages/meta/index')),
      params: {
        types: [
          TemplateType.Provider,
          TemplateType.Taxonomy,
          TemplateType.Filtertaxonomyitem,
          TemplateType.Product,
        ],
        topSelector: {
          network: { disabled: false, autoSet: true },
          vertical: { disabled: false },
          site: { disabled: false },
        },
      },
    },
  },
  {
    path: 'ranking',
    menu: {
      left: [
        {
          title: constant('Ranking'),
          permissions: [permissions.rank.view],
          icon: 'icmn icmn-list-numbered',
        },
      ],
    },
    children: [
      // {
      //   path: 'manage-rankings',
      //   menu: {
      //     left: [
      //       {
      //         title: constant('Manage Rankings'),
      //         permissions: [permissions.rank.view],
      //       },
      //     ],
      //   },
      //   component: {
      //     Component: lazy(() => import('@pages/ranking/RankingManageRankings')),
      //     params: {
      //       topSelector: {
      //         network: { disabled: false },
      //         vertical: { disabled: false },
      //         site: { disabled: false },
      //       },
      //     },
      //   },
      // },
      {
        path: 'provider-position',
        menu: {
          left: [
            {
              title: constant('Provider Position'),
              permissions: [permissions.rank.view],
            },
          ],
        },
        component: {
          Component: lazy(
            () => import('@pages/ranking/RankingProviderPosition'),
          ),
          params: {
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false, autoSet: true },
              site: { disabled: false },
            },
          },
        },
      },
    ],
  },
  {
    path: 'menu',
    menu: {
      left: [
        {
          title: constant('Menus'),
          permissions: [permissions.template.view],
          icon: 'icmn icmn-barcode',
        },
      ],
    },
    component: {
      Component: lazy(() => import('@pages/menu/index')),
      params: {
        types: [TemplateType.Menuitem],
        topSelector: {
          network: { disabled: false },
          vertical: { disabled: false, autoSet: true },
          site: { disabled: false },
        },
      },
    },
  },
  // {
  //   path: 'compliance',
  //   menu: {
  //     left: [
  //       {
  //         title: constant('Compliance rules'),
  //         permissions: [permissions.compliance.view],
  //         icon: 'icmn icmn-pen',
  //       },
  //     ],
  //   },
  //   component: {
  //     Component: lazy(() => import('@pages/compliance')),
  //     params: {
  //       topSelector: {
  //         network: { disabled: false },
  //         vertical: {
  //           selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
  //           disabled: true,
  //         },
  //         site: {
  //           selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
  //           disabled: true,
  //         },
  //       },
  //     },
  //   },
  // },
  {
    menu: {
      left: [
        {
          divider: true,
        },
      ],
    },
  },
  {
    path: 'export-import',
    menu: {
      left: [
        {
          title: constant('Export/Import data'),
          permissions: [permissions.export.view, permissions.import.view],
          icon: 'icmn icmn-cloud-check',
        },
      ],
    },
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('List'),
              permissions: [permissions.export.view, permissions.import.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/exportImport/list')),
          params: {
            topSelector: {
              network: {
                disabled: true,
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
              },
              vertical: {
                disabled: true,
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
              },
              site: {
                disabled: true,
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
              },
            },
          },
        },
      },
      {
        path: 'export',
        menu: {
          left: [
            {
              title: constant('Add Export'),
              permissions: [permissions.export.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/exportImport/export')),
          params: {
            types: [
              TemplateType.Site,
              TemplateType.Provider,
              TemplateType.Taxonomy,
              TemplateType.Filtertaxonomyitem,
              TemplateType.Product,
              TemplateType.Post,
              TemplateType.Page,
              TemplateType.Author,
              TemplateType.Providertaxonomyconnection,
            ],
            topSelector: {
              network: { disabled: false },
              vertical: {
                disabled: false,
                autoSet: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
    ],
  },
  {
    path: 'users',
    children: [
      {
        path: 'list',
        menu: {
          left: [
            {
              title: constant('Users'),
              icon: 'icmn icmn-user',
              permissions: [permissions.user.view],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/user/list')),
          params: {
            topSelector: {
              network: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        component: {
          Component: lazy(() => import('@pages/user/crud')),
          params: {
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              vertical: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
              site: {
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
                disabled: true,
              },
            },
          },
        },
      },
    ],
  },
  {
    path: 'networks',
    menu: {
      left: [
        {
          title: constant('Network'),
          permissions: [permissions.network.view],
          icon: 'icmn icmn-tree',
        },
      ],
    },
    children: [
      {
        path: 'edit',
        menu: {
          left: [
            {
              title: constant('Edit'),
              permissions: [permissions.network.edit],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/network/crud')),
          params: {
            types: [TemplateType.Network],
            mode: DynamicFormModes.Edit,
            topSelector: {
              network: { disabled: false, autoSet: true },
              vertical: { disabled: false },
              site: { disabled: false },
            },
          },
        },
      },
      {
        path: 'add',
        menu: {
          left: [
            {
              title: constant('Add'),
              permissions: [permissions.network.create],
            },
          ],
        },
        component: {
          Component: lazy(() => import('@pages/network/crud')),
          params: {
            types: [TemplateType.Network],
            mode: DynamicFormModes.Add,
            topSelector: {
              network: {
                disabled: true,
                autoSet: true,
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
              },
              vertical: {
                disabled: true,
                autoSet: true,
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
              },
              site: {
                disabled: true,
                autoSet: true,
                selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
              },
            },
          },
        },
      },
    ],
  },
  {
    path: 'templates/list',
    menu: {
      left: [
        {
          title: constant('Templates'),
          permissions: [permissions.template.view],
          icon: 'icmn icmn-cog',
        },
      ],
    },
    component: {
      Component: lazy(() => import('@pages/template/list')),
      params: {
        topSelector: {
          network: { disabled: false, autoSet: true },
          vertical: {
            selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
            disabled: true,
          },
          site: { selected: DEFAULT_SELECTOR_SEARCH_PARAM_KEY, disabled: true },
        },
      },
    },
  },
  {
    path: 'api-explorer',
    menu: {
      left: [
        {
          title: constant('API Explorer'),
          permissions: [permissions.api.view],
          icon: 'icmn icmn-search',
        },
      ],
    },
    component: {
      Component: lazy(() => import('@pages/api-explorer/index')),
      params: {
        topSelector: {
          network: { disabled: false },
          vertical: { disabled: false },
          site: { disabled: false },
        },
      },
    },
  },
  {
    path: '403',
    component: { Component: lazy(() => import('@pages/403')) },
  },
  {
    path: '*',
    component: { Component: lazy(() => import('@pages/404')) },
  },
]

function getTitleForType(defaultTitle, templateType) {
  return (templates, language) => {
    const template = getTemplate({
      type: templateType,
      templates,
    })

    return getTitle(defaultTitle, template, language)
  }
}

function getTitle(defaultTitle, template, language) {
  return (
    pluralize(
      getStringFromContent(TITLE_KEY, {
        content: template?.content,
        language,
      }) || '',
    ) || defaultTitle
  )
}

function getProductsMenu(templates) {
  const productTemplates = getTemplates({
    type: TemplateType.Product,
    templates,
  })

  const workingProductTemplates = productTemplates.filter(
    template => template.subtype,
  )

  return workingProductTemplates.map(template => {
    return {
      title: (_, language) => getTitle('Products', template, language),
      permissions: [permissions.product.view],
      icon: 'icmn icmn-barcode',
      url: template.subtype,
    }
  })
}
