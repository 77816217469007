import {
  renderDivider,
  renderMenuItem,
  renderMenuLink,
  renderSubMenu,
} from './renderParts'

export const generateItem = (allowed, item, generateMenu, isMenuCollapsed) => {
  if (item.children) {
    const subMenuItems = generateMenu(allowed, item.children, generateItem)
    return renderSubMenu(item, subMenuItems, isMenuCollapsed)
  }

  if (item.divider) {
    return renderDivider(item)
  }

  if (item.url) {
    return renderMenuLink(item)
  }

  return renderMenuItem(item)
}
