import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Product, ProductsState } from './types'

const initialState: ProductsState = {
  list: [],
  loading: true,
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<Product[]>) {
      state.list = action.payload
      state.loading = false
    },
    setProductsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

export default productsSlice.reducer

export const { setProducts, setProductsLoading } = productsSlice.actions
