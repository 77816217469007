import * as permissions from "../permissions";
import { Role } from "../../index";
import flat from "flat";

export default [
  {
    name: "admin",
    permissions: Object.values(flat.flatten(permissions)),
  },
  {
    name: "editor",
    permissions: [
      permissions.site.view,
      permissions.provider.view,
      permissions.provider.create,
      permissions.provider.edit,
      permissions.provider.delete,
      permissions.taxonomy.view,
      permissions.taxonomy.create,
      permissions.taxonomy.edit,
      permissions.taxonomy.delete,
      permissions.filterTaxonomy.view,
      permissions.filterTaxonomy.create,
      permissions.filterTaxonomy.edit,
      permissions.filterTaxonomy.delete,
      permissions.product.view,
      permissions.product.edit,
      permissions.product.create,
      permissions.product.delete,
      permissions.author.view,
      permissions.author.create,
      permissions.author.delete,
      permissions.author.edit,
      permissions.post.view,
      permissions.post.create,
      permissions.post.edit,
      permissions.post.delete,
      permissions.page.view,
      permissions.page.create,
      permissions.page.edit,
      permissions.page.delete,
      permissions.status.view,
      permissions.link.edit,
      permissions.link.create,
      permissions.link.delete,
      permissions.link.view,
      permissions.vertical.view,
      permissions.menu.view,
      permissions.menu.create,
      permissions.menu.delete,
      permissions.menu.edit,
      permissions.translation.view,
      permissions.translation.create,
      permissions.translation.delete,
      permissions.translation.edit,
      permissions.sitemap.view,
    ],
  },
  {
    name: "viewer",
    permissions: [
      permissions.provider.view,
      permissions.post.view,
      permissions.page.view,
      permissions.product.view,
      permissions.taxonomy.view,
      permissions.filterTaxonomy.view,
    ],
  },
] as Array<Role>;
