import { useAuth0 } from '@auth0/auth0-react'
import { getCustomClaim, hasPermissions } from '@wdnsolutions/auth-helpers'
import { useCallback, useEffect, useState } from 'react'

import { extractPermissionsFromMetadata } from '../../constants'

export type Permission = string

export const usePermissions = () => {
  const { getIdTokenClaims } = useAuth0()
  const [userPermissions, setUserPermissions] = useState<Permission[]>()

  useEffect(() => {
    let isMounted = true
    ;(async () => {
      const claims = await getIdTokenClaims()
      if (!isMounted) {
        return
      }

      const app_metadata = getCustomClaim(claims, 'app_metadata')
      const permissions = extractPermissionsFromMetadata(app_metadata)
      setUserPermissions(permissions)
    })()

    return () => {
      isMounted = false
    }
  }, [getIdTokenClaims])

  const allowed = useCallback(
    (permissions: Permission[] = []) => {
      if (!userPermissions) return false
      if (permissions.length === 0) return true
      return hasPermissions(permissions, userPermissions)
    },
    [userPermissions],
  )

  return {
    allowed,
  }
}
