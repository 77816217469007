import _roles from "./constant/roles/index";
import * as _permissions from "./constant/permissions";
import * as _RoleHandler from "./modules/roles";
import flat from "flat";
import * as jwt from "jsonwebtoken";
import uniq from "lodash/uniq";

export const roles = _roles;
export const permissions = _permissions;
export const RoleHandler = _RoleHandler;

export type Role = {
  name: string;
  permissions: Array<any>;
};

export const claimNamespace = "https://auth.wd.network/";

export const getCustomClaim = (claims: any, key: string) => {
  return claims[`${claimNamespace}${key}`];
};

export const hasPermissions = (
  permissions: Array<string> | string,
  userPermissions: Array<string>
) => {
  if (Array.isArray(permissions)) {
    return permissions.reduce((prev: boolean, curr) => {
      return prev && userPermissions.some((p) => p === curr);
    }, true);
  }

  return userPermissions.some((p) => p === permissions);
};

export const getAllPermissions = () => {
  const flattened = flat.flatten(permissions) as any;
  return Object.values(flattened) as Array<string>;
};

export const extractPermissionsFromMetadata = (app_metadata: any) => {
  const roles = (app_metadata?.roles ?? [])
    ?.map((role: any) => {
      return RoleHandler.getRole(role?.name);
    })
    .filter((e?: Role) => e);

  return [
    ...(app_metadata?.permissions ?? []).map(
      (permission: any) => permission.name
    ),
    ...RoleHandler.extractPermissions(roles),
  ];
};

export const verifyToken = (
  token: string,
  secretKeys: string[],
  options: jwt.VerifyOptions | undefined
): any => {
  let decoded;
  let errors: Error[] = [];

  for (let i = 0; i < secretKeys.length; i++) {
    const secretKey = secretKeys[i];
    if (!decoded) {
      try {
        decoded = jwt.verify(
          token,
          secretKey,
          options ?? {
            audience: "https://api.wdnsolutions.com/",
          }
        );
      } catch (error) {
        errors.push(error as any);
      }
    }
  }

  if (!decoded) {
    throw new Error(uniq(errors.map((error) => error.message)).join(", "));
  }

  return decoded;
};
