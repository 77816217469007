import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LiveUsersState } from './types'

const initialState: LiveUsersState = {
  list: [],
}

const liveUsersSlice = createSlice({
  name: 'liveUsers',
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<any>) {
      state.list = action.payload
    },
  },
})

export default liveUsersSlice.reducer

export const { setUsers } = liveUsersSlice.actions
