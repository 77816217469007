import { combineReducers } from '@reduxjs/toolkit'
import storage from 'localforage'
import { persistReducer } from 'redux-persist'

import countsReducer from './slices/counts'
import editorReducer from './slices/editor'
import languagesReducer from './slices/languages'
import liveUsersReducer from './slices/liveUsers'
import menuReducer from './slices/menu'
import networksReducer from './slices/networks'
import notificationsReducer from './slices/notifications'
import poolsReducer from './slices/pools'
import productsReducer from './slices/products'
import settingsReducer from './slices/settings'
import sitesReducer from './slices/sites'
import allTaxonomyItemsReducer from './slices/taxonomies'
import templatesReducer from './slices/templates'
import translationReducer from './slices/translation'
import verticalsReducer from './slices/verticals'

const menuPersistConfig = {
  key: 'menu',
  storage,
}
const networksPersistConfig = {
  key: 'networks',
  storage,
  whitelist: ['currentNetworkId', 'list'],
}
const verticalsPersistConfig = {
  key: 'verticals',
  storage,
  whitelist: ['currentVerticalId', 'list'],
}
const sitesPersistConfig = {
  key: 'sites',
  storage,
  whitelist: ['currentSiteId', 'list'],
}
const templatesPersistConfig = {
  key: 'templates',
  storage,
  whitelist: ['list', 'allList'],
}
const allTaxonomyItemsPersistConfig = {
  key: 'allTaxonomyItems',
  storage,
  whitelist: ['list'],
}
const editorPersistConfig = {
  key: 'editor',
  storage,
  whitelist: ['variables'],
}
const languagesPersistConfig = {
  key: 'languages',
  storage,
  whitelist: ['list'],
}
const countsPersistConfig = {
  key: 'counts',
  storage,
  whitelist: ['providersCount', 'taxonomyItemsCount'],
}
const poolsPersistConfig = {
  key: 'pools',
  storage,
  whitelist: ['providerPools', 'taxonomyItemPools'],
}

const rootReducer = combineReducers({
  languages: persistReducer(languagesPersistConfig, languagesReducer),
  networks: persistReducer(networksPersistConfig, networksReducer),
  verticals: persistReducer(verticalsPersistConfig, verticalsReducer),
  sites: persistReducer(sitesPersistConfig, sitesReducer),
  counts: persistReducer(countsPersistConfig, countsReducer),
  notifications: notificationsReducer,
  templates: persistReducer(templatesPersistConfig, templatesReducer),
  settings: settingsReducer,
  liveUsers: liveUsersReducer,
  menu: persistReducer(menuPersistConfig, menuReducer),
  allTaxonomyItems: persistReducer(
    allTaxonomyItemsPersistConfig,
    allTaxonomyItemsReducer,
  ),
  editor: persistReducer(editorPersistConfig, editorReducer),
  translation: translationReducer,
  products: productsReducer,
  pools: persistReducer(poolsPersistConfig, poolsReducer),
})

export default rootReducer
