import { useAuth0 } from '@auth0/auth0-react'
import {
  DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
  DEFAULT_SELECTOR_STORE_PARAM_KEY,
  USER_SELECTED_NETWORK_KEY,
  USER_SELECTED_VERTICAL_KEY,
} from '@constants'
import StorageHelper from '@modules/storage'
import {
  setCurrentNetwork,
  setNetworksSelectorConfig,
} from '@store/slices/networks'
import {
  currentNetworkIdSelector,
  defaultNetworkSelector,
  networksListSelector,
  networksLoadingSelector,
  networksSelectorConfigSelector,
} from '@store/slices/networks/selectors'
import { setCurrentSite, setSitesSelectorConfig } from '@store/slices/sites'
import {
  currentSiteIdSelector,
  sitesLoadingSelector,
  sitesSelectorConfigSelector,
} from '@store/slices/sites/selectors'
import {
  setCurrentVertical,
  setVerticalsSelectorConfig,
} from '@store/slices/verticals'
import {
  currentVerticalIdSelector,
  defaultVerticalSelector,
  verticalsListSelector,
  verticalsLoadingSelector,
  verticalsSelectorConfigSelector,
} from '@store/slices/verticals/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import isEqual from 'lodash/isEqual'
import qs from 'query-string'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

interface TopSelector {
  network: SelectorConfigBeginState
  vertical: SelectorConfigBeginState
  site: SelectorConfigBeginState
}

export type SelectorConfigBeginState = {
  selected?: string
  disabled?: boolean
  autoSet?: boolean
}

export interface ISearchParams {
  network?: string
  vertical?: string
  site?: string
}

export function useTopSelector(topSelector: TopSelector) {
  const location = useLocation()
  const history = useHistory()
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()
  const networkLoading = useAppSelector(networksLoadingSelector)
  const verticalLoading = useAppSelector(verticalsLoadingSelector)
  const siteLoading = useAppSelector(sitesLoadingSelector)
  const networksSelectorConfig = useAppSelector(networksSelectorConfigSelector)
  const verticalsSelectorConfig = useAppSelector(
    verticalsSelectorConfigSelector,
  )
  const sitesSelectorConfig = useAppSelector(sitesSelectorConfigSelector)
  const currentNetworkId = useAppSelector(currentNetworkIdSelector)
  const currentVerticalId = useAppSelector(currentVerticalIdSelector)
  const currentSiteId = useAppSelector(currentSiteIdSelector)
  const defaultVertical = useAppSelector(defaultVerticalSelector)
  const verticals = useAppSelector(verticalsListSelector)
  const defaultNetwork = useAppSelector(defaultNetworkSelector)
  const networks = useAppSelector(networksListSelector)

  // TODO: try to use top selector only for main layout
  const isAutoSettingDisabled = location.pathname.includes('user/')

  // set saved network if no such in seach params
  useEffect(() => {
    if (isAutoSettingDisabled) return
    const newParamsObj: ISearchParams = qs.parse(location.search)
    const userSelectedNetwork = getCorrectSelectorStoreValue(
      StorageHelper.getItem(USER_SELECTED_NETWORK_KEY),
    )

    if (!newParamsObj.network && userSelectedNetwork) {
      newParamsObj.network = userSelectedNetwork
      newParamsObj.vertical = DEFAULT_SELECTOR_SEARCH_PARAM_KEY
      newParamsObj.site = DEFAULT_SELECTOR_SEARCH_PARAM_KEY
      history.push({ search: qs.stringify(newParamsObj) })
    }
    // we need only didmount lifecycle moment
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // set default network if no network selected
  useEffect(() => {
    const newParamsObj: ISearchParams = qs.parse(location.search)
    if (
      networkLoading ||
      !defaultNetwork ||
      topSelector?.network?.selected === DEFAULT_SELECTOR_SEARCH_PARAM_KEY ||
      !topSelector?.network?.autoSet ||
      isAutoSettingDisabled
    )
      return

    let autoSetNetwork = defaultNetwork
    // get selected vertical by user
    const userSelectedNetworkId = getCorrectSelectorStoreValue(
      StorageHelper.getItem(USER_SELECTED_NETWORK_KEY),
    )
    if (userSelectedNetworkId) {
      const selectedNetwork = networks?.find(
        network => network.id === userSelectedNetworkId,
      )
      if (selectedNetwork) autoSetNetwork = selectedNetwork
    }

    if (
      newParamsObj.network === DEFAULT_SELECTOR_SEARCH_PARAM_KEY ||
      typeof newParamsObj.network === 'undefined'
    ) {
      newParamsObj.network =
        autoSetNetwork?.id || DEFAULT_SELECTOR_SEARCH_PARAM_KEY
      history.push({ search: qs.stringify(newParamsObj) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    networkLoading,
    defaultNetwork,
    location.search,
    history,
    dispatch,
    currentNetworkId,
    topSelector?.network?.selected,
    topSelector?.network?.autoSet,
  ])

  // set default vertical if no vertical selected
  useEffect(() => {
    const newParamsObj: ISearchParams = qs.parse(location.search)
    if (
      !getCorrectSelectorStoreValue(newParamsObj.network) ||
      verticalLoading ||
      !defaultVertical ||
      defaultVertical.network?.id !== currentNetworkId ||
      newParamsObj.network !== getCorrectSelectorParamValue(currentNetworkId) ||
      topSelector?.vertical?.selected === DEFAULT_SELECTOR_SEARCH_PARAM_KEY ||
      !topSelector?.vertical?.autoSet ||
      isAutoSettingDisabled
    )
      return

    let autoSetVertical = defaultVertical
    // get selected vertical by user
    const userSelectedVerticalId = getCorrectSelectorStoreValue(
      StorageHelper.getItem(USER_SELECTED_VERTICAL_KEY),
    )
    if (userSelectedVerticalId) {
      const selectedVertical = verticals?.find(
        vertical => vertical.id === userSelectedVerticalId,
      )
      if (selectedVertical) autoSetVertical = selectedVertical
    }

    if (
      newParamsObj.network &&
      (newParamsObj.vertical === DEFAULT_SELECTOR_SEARCH_PARAM_KEY ||
        typeof newParamsObj.vertical === 'undefined')
    ) {
      newParamsObj.vertical =
        autoSetVertical?.id || DEFAULT_SELECTOR_SEARCH_PARAM_KEY
      history.push({ search: qs.stringify(newParamsObj) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    verticalLoading,
    defaultVertical,
    location.search,
    history,
    dispatch,
    currentNetworkId,
    topSelector?.vertical?.selected,
    topSelector?.vertical?.autoSet,
  ])

  // search params to store values
  useEffect(() => {
    if (networkLoading || verticalLoading || siteLoading) {
      return
    }

    const newParamsObj: ISearchParams = qs.parse(location.search)
    const oldParamsObj: ISearchParams = {
      network: getCorrectSelectorParamValue(currentNetworkId),
      vertical: getCorrectSelectorParamValue(currentVerticalId),
      site: getCorrectSelectorParamValue(currentSiteId),
    }

    if (!isEqual(newParamsObj, oldParamsObj)) {
      if (!topSelector?.network.disabled && !topSelector?.network.selected) {
        dispatch(
          setCurrentNetwork(getCorrectSelectorStoreValue(newParamsObj.network)),
        )
      } else if (topSelector?.network.selected) {
        dispatch(
          setCurrentNetwork(
            getCorrectSelectorStoreValue(topSelector?.network.selected),
          ),
        )
      }

      if (!topSelector?.vertical.disabled && !topSelector?.vertical.selected) {
        dispatch(
          setCurrentVertical(
            getCorrectSelectorStoreValue(newParamsObj.vertical),
          ),
        )
      } else if (topSelector?.vertical.selected) {
        dispatch(
          setCurrentVertical(
            getCorrectSelectorStoreValue(topSelector?.vertical.selected),
          ),
        )
      }

      if (!topSelector?.site.disabled && !topSelector?.site.selected) {
        dispatch(
          setCurrentSite(getCorrectSelectorStoreValue(newParamsObj.site)),
        )
      } else if (topSelector?.site.selected) {
        dispatch(
          setCurrentSite(
            getCorrectSelectorStoreValue(topSelector?.site.selected),
          ),
        )
      }
    }
  }, [
    location.search,
    networkLoading,
    verticalLoading,
    siteLoading,
    currentNetworkId,
    currentVerticalId,
    currentSiteId,
    dispatch,
    topSelector?.network.disabled,
    topSelector?.vertical.disabled,
    topSelector?.site.disabled,
    topSelector?.network.selected,
    topSelector?.vertical.selected,
    topSelector?.site.selected,
  ])

  // selector config to store selector config values
  useEffect(() => {
    if (!isAuthenticated || networkLoading || verticalLoading || siteLoading) {
      return
    }

    const paramsObj: ISearchParams = qs.parse(location.search)
    const newParamsObj: ISearchParams = { ...paramsObj }

    if (topSelector) {
      if (!isEqual(networksSelectorConfig, topSelector.network)) {
        dispatch(setNetworksSelectorConfig(topSelector.network))
        if (
          topSelector.network.selected &&
          paramsObj.network !== topSelector.network.selected
        ) {
          newParamsObj.network = topSelector.network.selected
        }
        dispatch(setNetworksSelectorConfig(topSelector.network))
        if (
          topSelector.network.selected &&
          paramsObj.network !== topSelector.network.selected
        ) {
          newParamsObj.network = topSelector.network.selected
        }
      }
      if (!isEqual(verticalsSelectorConfig, topSelector.vertical)) {
        dispatch(setVerticalsSelectorConfig(topSelector.vertical))
        if (
          topSelector.vertical.selected &&
          paramsObj.vertical !== topSelector.vertical.selected
        ) {
          newParamsObj.vertical = topSelector.vertical.selected
        }
      }
      if (!isEqual(sitesSelectorConfig, topSelector.site)) {
        dispatch(setSitesSelectorConfig(topSelector.site))
        if (
          topSelector.site.selected &&
          paramsObj.site !== topSelector.site.selected
        ) {
          newParamsObj.site = topSelector.site.selected
        }
      }
    }

    if (!isEqual(newParamsObj, paramsObj)) {
      history.push({
        search: qs.stringify(newParamsObj),
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    networkLoading,
    verticalLoading,
    siteLoading,
    topSelector,
    networksSelectorConfig,
    verticalsSelectorConfig,
    sitesSelectorConfig,
    isAuthenticated,
    dispatch,
    history,
  ])
}

// to keep in store correct value
const getCorrectSelectorStoreValue = value => {
  if (value === DEFAULT_SELECTOR_SEARCH_PARAM_KEY) {
    return DEFAULT_SELECTOR_STORE_PARAM_KEY
  }
  return value
}

// to format store value for search params
const getCorrectSelectorParamValue = value => {
  if (value === DEFAULT_SELECTOR_STORE_PARAM_KEY) {
    return DEFAULT_SELECTOR_SEARCH_PARAM_KEY
  }
  return value
}
