import { DEFAULT_LANGUAGE } from '@constants'
import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../index'

const sitesList = (state: RootState) => state.sites.list

const sitesSelectorConfig = (state: RootState) => state.sites.selectorConfig

const currentLanguage = (state: RootState) => state.sites.currentLanguage

const currentSiteId = (state: RootState) => state.sites.currentSiteId

export const sitesListSelector = createSelector(
  [sitesList],
  sitesList => sitesList,
)

export const sitesSelectorConfigSelector = createSelector(
  [sitesSelectorConfig],
  sitesSelectorConfig => sitesSelectorConfig,
)

export const currentSiteIdSelector = createSelector(
  [currentSiteId],
  currentSiteId => currentSiteId,
)

export const currentSiteSelector = createSelector(
  [sitesList, currentSiteId],
  (sitesList, currentSiteId) =>
    sitesList.find(site => site.id === currentSiteId),
)

export const defaultSiteSelector = createSelector([sitesList], sitesList =>
  sitesList.find(
    site => site.language.languageTag === DEFAULT_LANGUAGE.languageTag,
  ),
)

export const currentLanguageSelector = createSelector(
  [sitesList, currentSiteId, currentLanguage],
  (sitesList, currentSiteId, currentLanguage) =>
    sitesList.find(site => site.id === currentSiteId)?.language ||
    currentLanguage ||
    DEFAULT_LANGUAGE,
)

export const sitesLoadingSelector = createSelector(
  [sitesList, (state: RootState) => state.sites.loading],
  (sitesList, loading) => !sitesList?.length && loading,
)
