import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export const LinkWithQuery = ({
  children,
  to,
  customSearch = undefined,
  ...props
}) => {
  const { search } = useLocation()
  const _search = customSearch ? `?${customSearch}` : search

  return (
    <Link to={to + _search} {...props}>
      {children}
    </Link>
  )
}
