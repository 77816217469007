import { ReloadOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import localforage from 'localforage'
import React from 'react'

const PurgeCache = () => {
  const onPurge = async () => {
    await localforage.clear()
    window.location.reload()
  }

  return (
    <Tooltip title="Purge cache">
      <Button
        className="d-flex align-items-center justify-content-center"
        shape="circle"
        onClick={onPurge}
        type="text"
        icon={
          <ReloadOutlined
            style={{
              color: '#fff',
            }}
          />
        }
      />
    </Tooltip>
  )
}
export default PurgeCache
