import { LinkWithQuery } from '@components/LinkWithQuery'
import React from 'react'

import styles from './style.module.scss'

export { renderDivider, renderMenuItem, renderMenuLink, renderSubMenu }

function renderSubMenu(item, children) {
  return {
    key: item.key,
    children,
    label: (
      <span className={styles.menu} key={item.key}>
        {item.icon && <span className={`${item.icon} ${styles.icon}`} />}
        <span className={styles.title}>{item.title}</span>
      </span>
    ),
  }
}

function renderMenuLink({ key, disabled, target, url, icon, title }) {
  return {
    key,
    disabled,
    label: (
      <>
        {target ? (
          <a href={url} target={target} rel="noopener noreferrer">
            {icon && <span className={`${icon} ${styles.icon}`} />}
            <span className={styles.title}>{title}</span>
          </a>
        ) : (
          <LinkWithQuery to={url}>
            {icon && <span className={`${icon} ${styles.icon}`} />}
            <span className={styles.title}>{title}</span>
          </LinkWithQuery>
        )}
      </>
    ),
  }
}

function renderMenuItem({ key, disabled, icon, title }) {
  return {
    key,
    disabled,
    label: (
      <>
        {icon && <span className={`${icon} ${styles.icon}`} />}
        <span className={styles.title}>{title}</span>
      </>
    ),
  }
}

function renderDivider({ key }) {
  return {
    key,
    type: 'divider',
  }
}
