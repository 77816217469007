import React, { FC } from 'react'
import ReactCountryFlag from 'react-country-flag'

import icon from './arabLeagueIcon.jpg'
import { ICountryFlagIcon } from './types'

const CountryFlagIcon: FC<ICountryFlagIcon> = ({ countryCode, style = {} }) => {
  return countryCode === 'arabic' ? ( // Arabic League (union of countries) doesn't have ISO countryCode
    <span style={{ marginLeft: '1px' }}>
      <img
        src={icon}
        alt="ALFlag"
        style={{
          width: '18px',
          height: '16px',
          ...style,
        }}
      />
    </span>
  ) : (
    <ReactCountryFlag
      countryCode={countryCode}
      style={{
        fontSize: '20px',
        lineHeight: '20px',
        ...style,
      }}
    />
  )
}

export default CountryFlagIcon
