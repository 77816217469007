import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AllTaxonomyItemsState, TaxonomyItem } from './types'

const initialState: AllTaxonomyItemsState = {
  list: [],
  loading: false,
  error: undefined,
}

const allTaxonomyItemsSlice = createSlice({
  name: 'allTaxonomyItems',
  initialState,
  reducers: {
    setAllTaxonomyItems(state, action: PayloadAction<TaxonomyItem[]>) {
      state.list = action.payload
      state.loading = false
    },
    setAllTaxonomyItemsError(state, action: PayloadAction<string>) {
      state.error = action.payload
      state.loading = false
    },
  },
})

export default allTaxonomyItemsSlice.reducer

export const { setAllTaxonomyItems, setAllTaxonomyItemsError } =
  allTaxonomyItemsSlice.actions
