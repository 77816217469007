import { DEFAULT_LANGUAGE } from '@constants'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Settings, SettingsState } from './types'

const initialState: SettingsState = {
  language: DEFAULT_LANGUAGE.languageTag,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings(state, action: PayloadAction<Settings>) {
      state = { ...state, ...action.payload }
    },
  },
})

export default settingsSlice.reducer

export const { setSettings } = settingsSlice.actions
