export default {
  edit: "user:edit",
  create: "user:create",
  delete: "user:delete",
  view: "user:view",
  role: {
    edit: "user:role:edit",
  },
  permission: {
    edit: "user:permission:edit",
  },
};
