import React from 'react'

import { useFetchTemplates } from './hooks/useFetchTemplates'
import { useTopSelector } from './hooks/useTopSelector'

const TopSelectorSet = ({ types, topSelector, children }) => {
  useTopSelector(topSelector)
  useFetchTemplates(types)
  return <>{children}</>
}

export default TopSelectorSet
