import { useAuth0 } from '@auth0/auth0-react'
import { useLanguagesLazyQuery } from '@generated/graphql'
import { setLanguages } from '@store/slices/languages'
import { languagesListSelector } from '@store/slices/languages/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { useEffect } from 'react'

export function useFetchLanguages() {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const languages = useAppSelector(languagesListSelector)

  const [queryLanguages] = useLanguagesLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ languages }) => {
      dispatch(setLanguages(languages))
    },
    onError: error => {
      console.error('Unable to load languages', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated || languages?.length) {
      return
    }

    queryLanguages()
  }, [isAuthenticated, queryLanguages])
}
