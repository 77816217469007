import { initializeApp } from 'firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
} from 'firebase/auth'
import * as rtdbMethods from 'firebase/database'
import { useEffect, useState } from 'react'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASEURL,
  projectId: process.env.REACT_APP_FB_PROJECTID,
  appId: process.env.REACT_APP_FB_APPID,
}

const firebaseApp = initializeApp(firebaseConfig)

export const fb = {
  db: rtdbMethods.getDatabase(), // reexport for convenience
  ...rtdbMethods,
}

export const firebaseSignIn = async getAccessTokenSilently => {
  const auth = getAuth(firebaseApp)
  try {
    const mainToken = await getAccessTokenSilently()
    const url =
      process.env.REACT_APP_API_ENDPOINT +
      process.env.REACT_APP_API_PATH +
      'firebase-token'
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${mainToken}`,
      },
    })
    const { fbToken } = await response.json()
    const { user } = await signInWithCustomToken(auth, fbToken)
    return user
  } catch (err) {
    console.log('Firebase authentication error')
    console.log(err)
    return null
  }
}

export const useFirebaseAuthStatus = () => {
  const [state, setState] = useState(null)

  useEffect(() => {
    if (process.env.REACT_APP_FB_OFF) {
      return
    }

    const unsubscribe = onAuthStateChanged(getAuth(firebaseApp), user => {
      if (user) {
        setState(user.uid)
      } else {
        setState(null)
      }
    })
    return () => unsubscribe()
  }, [])

  return state
}
