import { DEFAULT_LANGUAGE, GLOBAL_CONTEXT_MARKER } from '@constants'
import { ContentItemTranslationType } from '@generated/graphql'
import capitalize from 'lodash/capitalize'
import reactStringReplace from 'react-string-replace'

import { ExtendedContentItemTitleEnum } from './types'

const DEFAULT_LANGUAGE_TAG = DEFAULT_LANGUAGE.languageTag

export const checkStringItem = ({
  string,
  config: { language, network, vertical, site },
}) => {
  return (
    string?.language?.languageTag === language &&
    (string.contextId === network ||
      string.contextId === GLOBAL_CONTEXT_MARKER ||
      string.contextId === vertical ||
      string.contextId === site ||
      string.contextId === null)
  )
}

export const getStringFromContent = (key, options) => {
  const {
    language,
    content = [],
    defaultLanguage = DEFAULT_LANGUAGE_TAG,
    defaultSuffix = ` - (${defaultLanguage})`,
    respectTranslationType = true,
  } = options

  const contentItem = Array.from(content).find(item => item.key === key)
  if (!contentItem) {
    return ''
  }

  const _language =
    respectTranslationType &&
    contentItem.translationType === ContentItemTranslationType.Notranslate
      ? DEFAULT_LANGUAGE.languageTag
      : language

  let string = findStringByLanguage(
    Array.from(contentItem?.strings ?? []),
    _language,
  )

  if (string) return string.data || ''

  // Use DEFAULT_LANGUAGE if unable to find string for current language
  if (defaultLanguage !== DEFAULT_LANGUAGE_TAG) {
    string = findStringByLanguage(
      Array.from(contentItem?.strings ?? []),
      DEFAULT_LANGUAGE_TAG,
    )

    return `${string?.data}${defaultSuffix}`
  }

  return ''
}

function findStringByLanguage(strings, languageTag) {
  return findStringBy(
    strings,
    string => string?.language?.languageTag === languageTag,
  )
}

function findStringBy(strings = [], checkBy) {
  return strings.find(checkBy)
}

/**
 *
 * @param {*} strings
 * @param {object} options
 * @returns
 */
export function findStringByLanguageAndContext(
  strings,
  { language, network, vertical, site },
) {
  return findStringBy(strings, string =>
    checkStringItem({
      string,
      config: {
        language,
        network: network?.id,
        vertical: vertical?.id,
        site: site?.id,
      },
    }),
  )
}

/**
 *
 * @param {*} key
 * @param {object} options
 * @param {*} options.languageTag
 * @param {*} options.content
 * @param {*} [options.defaultLanguage]
 * @param {*} [options.defaultSuffix]
 * @param {*} [options.network]
 * @param {*} [options.vertical]
 * @param {*} [options.site]
 * @returns {string}
 */
export const getStringFromContentInContext = (key, options) => {
  const {
    language,
    content = [],
    defaultLanguage = DEFAULT_LANGUAGE,
    defaultSuffix = ` - (${defaultLanguage})`,
    respectTranslationType = true,
  } = options

  const contentItem = Array.from(content).find(item => item.key === key)
  if (!contentItem) {
    return ''
  }

  const _language =
    respectTranslationType &&
    contentItem.translationType === ContentItemTranslationType.Notranslate
      ? DEFAULT_LANGUAGE.languageTag
      : language

  let string = findStringByLanguageAndContext(
    Array.from(contentItem?.strings ?? []),
    { ...options, language: _language },
  )

  if (string) return string.data || ''

  // Use DEFAULT_LANGUAGE if unable to find string for current language
  if (defaultLanguage !== DEFAULT_LANGUAGE) {
    string = findStringByLanguageAndContext(
      Array.from(contentItem?.strings ?? []),
      { ...options, language: DEFAULT_LANGUAGE.languageTag },
    )

    return `${string?.data}${defaultSuffix}`
  }

  return ''
}

export const getStringWithSpecificContext = (
  key,
  { language, content = [], contexts = [] },
) => {
  const contentItem = Array.from(content).find(item => item?.key === key)
  const strings =
    contentItem?.strings?.filter(
      string => string?.language?.languageTag === language,
    ) ?? []
  let string
  string = contexts?.reduce((string, context) => {
    if (!string) {
      string = strings?.find(string => string?.contextId === context)
    }
    return string
  }, '')

  return string?.data
}

export const checkNoTranslateType = (contentItem = {}, currentLanguageTag) => {
  return !(
    contentItem.translationType === ContentItemTranslationType.Notranslate &&
    currentLanguageTag !== DEFAULT_LANGUAGE.languageTag
  )
}

export const formatExtendedContentItemTitle = title => {
  return reactStringReplace(title, /%(\w+)/g, match => {
    switch (match) {
      case ExtendedContentItemTitleEnum.Year:
        return new Date().getFullYear()
      case ExtendedContentItemTitleEnum.Provider:
        return capitalize(ExtendedContentItemTitleEnum.Provider)
      case ExtendedContentItemTitleEnum.Product:
        return capitalize(ExtendedContentItemTitleEnum.Product)
      case ExtendedContentItemTitleEnum.Category:
        return capitalize(ExtendedContentItemTitleEnum.Category)
      default:
        return null
    }
  }).join('')
}
