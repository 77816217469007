import ROLES from "../constant/roles/index";
import { Role } from "../index";
import * as flat from "flat";

export const extractPermissions = (roles: Role | Array<Role>) => {
  if (!Array.isArray(roles)) roles = [roles];

  const permissions = flat.flatten(
    roles.reduce((prev: Array<any>, curr) => {
      return [...prev, ...(curr?.permissions ?? [])];
    }, [])
  ) as any;

  return Object.keys(permissions).map((key) => {
    return permissions[key];
  });
};

export const getRole = (name: string) => {
  return ROLES.find((role) => {
    return role.name === name;
  });
};
