import { Tag } from 'antd'
import React, { FC } from 'react'

import styles from './styles.module.scss'
import { INewTag } from './types'

const NewTag: FC<INewTag> = ({ text, color }) => {
  return (
    <Tag className={styles.newTag} color={color}>
      {text}
    </Tag>
  )
}

export default NewTag
