export function generateMenuItems(
  allowed,
  menuData,
  generateItem,
  isMenuCollapsed,
) {
  return generateMenu(
    allowed,
    prepareMenuData(allowed, menuData),
    generateItem,
    isMenuCollapsed,
  )
}

function generateMenu(allowed, menuData, generateItem, isMenuCollapsed) {
  return menuData.map(item =>
    generateItem(allowed, item, generateMenu, isMenuCollapsed),
  )
}

function prepareMenuData(allowed, menuData, generatedKey = '') {
  return trimDividers(
    squashDividers(
      menuData.reduce((generatedMenuData, item, index) => {
        if (item.children) {
          if (item.disabled) {
            generatedMenuData.push({ ...item, children: null })
            return generatedMenuData
          }

          const preparedChildren = prepareMenuData(
            allowed,
            item.children,
            generatedKey + '.' + index,
          )
          if (preparedChildren.length > 0) {
            generatedMenuData.push({ ...item, children: preparedChildren })
          }
          return generatedMenuData
        }

        if (item.divider) {
          generatedMenuData.push({ ...item, key: generatedKey + '.' + index })
          return generatedMenuData
        }

        if (allowed(item.permissions)) {
          generatedMenuData.push(item)
          return generatedMenuData
        }

        return generatedMenuData
      }, []),
    ),
  )
}

function trimDividers(menuData) {
  let isElementFound = false
  let firstElementIndex = menuData.length + 1
  let lastElementIndex = 0

  menuData.forEach((item, index) => {
    if (item.divider) {
      return
    }

    lastElementIndex = index

    if (!isElementFound) {
      firstElementIndex = index
      isElementFound = true
    }
  })

  return menuData.slice(firstElementIndex, lastElementIndex + 1)
}

function squashDividers(menuData) {
  let isDividerAdded = false

  return menuData.reduce((resultData, item) => {
    if (item.divider) {
      if (!isDividerAdded) {
        resultData.push(item)
        isDividerAdded = true
      }
      return resultData
    }

    resultData.push(item)
    isDividerAdded = false
    return resultData
  }, [])
}

export function flattenItems(items, key) {
  return items.reduce((flattenedItems, item) => {
    flattenedItems.push(item)
    if (Array.isArray(item[key])) {
      return flattenedItems.concat(flattenItems(item[key], key))
    }
    return flattenedItems
  }, [])
}

export function getInitialParentKeys(menuData, selectedKey) {
  if (!selectedKey) {
    return []
  }

  return menuData.reduce((parentOpenKeys, { key: parentKey, children }) => {
    if (children?.some(({ key: childKey }) => childKey === selectedKey)) {
      parentOpenKeys.push(parentKey)
    }
    return parentOpenKeys
  }, [])
}
