import { DEFAULT_LANGUAGE, DEFAULT_SELECTOR_STORE_PARAM_KEY } from '@constants'
import { SelectorConfigBeginState } from '@pages/router/components/TopSelectorSet/hooks/useTopSelector'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Language } from '../languages/types'
import { Site, SitesState } from './types'

const initialState: SitesState = {
  list: [],
  currentSiteId: DEFAULT_SELECTOR_STORE_PARAM_KEY,
  currentLanguage: DEFAULT_LANGUAGE,
  loading: false,
  selectorConfig: {},
}

const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setSites(state, action: PayloadAction<Site[]>) {
      state.list = action.payload
      state.loading = false
    },
    resetSites(state) {
      state.list = initialState.list
    },
    setCurrentSite(state, action: PayloadAction<string>) {
      state.currentSiteId = action.payload
    },
    resetCurrentSite(state) {
      state.currentSiteId = initialState.currentSiteId
    },
    setCurrentLanguage(state, action: PayloadAction<Language>) {
      state.currentLanguage = action.payload
    },
    resetCurrentLanguage(state) {
      state.currentLanguage = initialState.currentLanguage
    },
    setSitesLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSitesSelectorConfig(
      state,
      action: PayloadAction<SelectorConfigBeginState>,
    ) {
      state.selectorConfig = action.payload
    },
  },
})

export default sitesSlice.reducer

export const {
  setSites,
  resetSites,
  setCurrentSite,
  resetCurrentSite,
  setCurrentLanguage,
  resetCurrentLanguage,
  setSitesLoading,
  setSitesSelectorConfig,
} = sitesSlice.actions
