import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import Loader from '@components/LayoutComponents/Loader'
import { createUploadLink } from 'apollo-upload-client'
import { persistCache } from 'apollo3-cache-persist'
import localforage from 'localforage'
import React, { FC, ReactNode, useEffect, useState } from 'react'

import introspectionResult from '../../generated/fragment-matcher'

const ApolloProviderConfigured: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const [client, setClient] = useState<any>()

  useEffect(() => {
    ;(async () => {
      const link = new createUploadLink({
        uri:
          (process.env.REACT_APP_API_ENDPOINT ||
            'https://api.wdnsolutions.com') +
          (process.env.REACT_APP_API_PATH || '/api/core'),
        headers: {
          'Cache-Control': 'no-cache',
        },
      })

      const authMiddleware = setContext(async (_, { headers }) => {
        const token = await getAccessTokenSilently()

        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        }
      })

      const cache = new InMemoryCache({
        resultCaching: false,
        possibleTypes: introspectionResult.possibleTypes,
      })

      // await before instantiating ApolloClient, else queries might run before the cache is persisted
      await persistCache({
        cache,
        storage: localforage,
        maxSize: false,
        debug: true,
      })

      const apolloConfig = {
        link: from([authMiddleware, link]),
        cache,
        connectToDevTools: false,
      }

      if (process.env.REACT_APP_APOLLO_DEVTOOLS) {
        apolloConfig.connectToDevTools = true
      }

      const _client = new ApolloClient(apolloConfig)
      setClient(_client)
    })()
  }, [getAccessTokenSilently])

  if (!client) return <Loader />
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloProviderConfigured
