import { setLeftMenuData, setTopMenuData } from '@store/slices/menu'
import * as data from '@store/slices/menu/helpers'
import { settingsSelector } from '@store/slices/settings/selectors'
import { allTemplatesSelector } from '@store/slices/templates/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { useEffect } from 'react'

export function useCreateMenu() {
  const dispatch = useAppDispatch()
  const templates = useAppSelector(allTemplatesSelector)
  const { language } = useAppSelector(settingsSelector)

  useEffect(() => {
    const menuLeftData = data.getLeftMenuData(templates, language)
    dispatch(setLeftMenuData(menuLeftData))
    const menuTopData = data.getTopMenuData(templates, language)
    dispatch(setTopMenuData(menuTopData))
  }, [dispatch, language, templates])
}
