import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IMenuItem, MenuState } from './types'

const initialState: MenuState = {
  menuLeftData: [],
  menuTopData: [],
}

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setLeftMenuData(state, action: PayloadAction<IMenuItem[]>) {
      state.menuLeftData = action.payload
    },
    setTopMenuData(state, action: PayloadAction<IMenuItem[]>) {
      state.menuTopData = action.payload
    },
  },
})

export default menuSlice.reducer

export const { setLeftMenuData, setTopMenuData } = menuSlice.actions
