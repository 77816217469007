import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EditorState, StringTemplateVariable } from './types'

const initialState: EditorState = {
  variables: [],
  variablesLoading: false,
  variablesError: undefined,
  activeEditorInstance: null,
  activeEditorSelectionRange: null,
  isDirty: false,
  isUnsavedChangesModal: false,
  isAutoGenerateImage: false,
}

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setVariables(state, action: PayloadAction<StringTemplateVariable[]>) {
      state.variables = action.payload
      state.variablesLoading = false
    },
    setVariablesError(state, action: PayloadAction<string>) {
      state.variablesError = action.payload
      state.variablesLoading = false
    },
    setActiveEditor(
      state,
      action: PayloadAction<{
        activeEditorInstance: any
        activeEditorSelectionRange: any
      }>,
    ) {
      state.activeEditorInstance = action.payload.activeEditorInstance
      state.activeEditorSelectionRange =
        action.payload.activeEditorSelectionRange
    },
    setEditorIsDirty(state, action: PayloadAction<boolean>) {
      state.isDirty = action.payload
    },
    setIsUnsavedChangesModal(
      state,
      action: PayloadAction<{
        isUnsavedChangesModal: boolean
        unsavedChangesDiscardAction?: () => void
      }>,
    ) {
      state.isUnsavedChangesModal = action.payload.isUnsavedChangesModal
      state.unsavedChangesDiscardAction =
        action.payload.unsavedChangesDiscardAction
    },
    setEditorIsAutoGenerateImage(state, action: PayloadAction<boolean>) {
      state.isAutoGenerateImage = action.payload
    },
  },
})

export default editorSlice.reducer

export const {
  setVariables,
  setVariablesError,
  setActiveEditor,
  setEditorIsDirty,
  setIsUnsavedChangesModal,
  setEditorIsAutoGenerateImage,
} = editorSlice.actions
