import { useAuth0 } from '@auth0/auth0-react'
import { useStringTemplateVariablesLazyQuery } from '@generated/graphql'
import { setVariables, setVariablesError } from '@store/slices/editor'
import { defaultLanguageIdSelector } from '@store/slices/languages/selectors'
import { currentNetworkSelector } from '@store/slices/networks/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { useEffect } from 'react'

export function useFetchVariables() {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const templatesLoading = useAppSelector(state => state.templates.loading)
  const taxonomiesLoading = useAppSelector(
    state => state.allTaxonomyItems.loading,
  )
  const currentNetwork = useAppSelector(currentNetworkSelector)
  const defaultLanguageId = useAppSelector(defaultLanguageIdSelector)
  const currentNetworkId = currentNetwork?.id

  const [query] = useStringTemplateVariablesLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ stringTemplateVariables = [] }) => {
      dispatch(setVariables(stringTemplateVariables))
    },
    onError: error => {
      console.error('Unable to load variables', error)
      dispatch(setVariablesError(error.message))
    },
  })

  useEffect(() => {
    if (
      !isAuthenticated ||
      !defaultLanguageId ||
      !currentNetworkId ||
      templatesLoading ||
      taxonomiesLoading
    ) {
      return
    }

    query({
      variables: {
        where: {
          enabled: {
            equals: true,
          },
          networkId: {
            equals: currentNetworkId,
          },
        },
      },
    })
  }, [
    isAuthenticated,
    query,
    defaultLanguageId,
    dispatch,
    currentNetworkId,
    templatesLoading,
    taxonomiesLoading,
  ])
}
