import logoSource from '@assets/images/logo-mobile.png'
import { LinkWithQuery } from '@components/LinkWithQuery'
import { Layout } from 'antd'
import React, { useState } from 'react'

import styles from './style.module.scss'

const LoginLayout = ({ children }) => {
  const [backgroundNumber /* setBackgroundNumber */] = useState(1)
  const [backgroundEnabled /* setBackgroundEnabled */] = useState(false)

  return (
    <Layout>
      <Layout.Content>
        <div
          className={
            backgroundEnabled
              ? `${styles.layout} ${styles.light}`
              : `${styles.layout}`
          }
          style={{
            backgroundImage: backgroundEnabled
              ? `url('resources/images/photos/${backgroundNumber}.jpeg')`
              : 'none',
          }}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <LinkWithQuery to="/">
                {!backgroundEnabled && (
                  <img src={logoSource} alt="Deployr Admin" />
                )}
                {backgroundEnabled && (
                  <img
                    src="resources/images/logo-inverse-2.png"
                    alt="Deployr Admin"
                  />
                )}
              </LinkWithQuery>
            </div>
            <div className={styles.controls}>
              {/* <div className="d-inline-block mr-3">
                  <Button type="default" onClick={this.changeBackground}>
                    Change Background
                  </Button>
                </div>
                <div className="d-inline-block">
                  <Button type="default" onClick={this.toggleBackground}>
                    Toggle Background
                  </Button>
                </div> */}
            </div>
            <nav className={styles.navigation}>
              <ul className={styles.navigationItems}>
                <li>
                  <div className={styles.navigationActive}>Login</div>
                </li>
              </ul>
            </nav>
          </div>
          <div className={styles.content}>{children}</div>
          <div className={`${styles.footer} text-center`}>
            <p>
              &copy; {new Date().getFullYear()} Deployr. All rights reserved.
            </p>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default LoginLayout
