import { useAuth0 } from '@auth0/auth0-react'
import { fb, useFirebaseAuthStatus } from '@modules/firebase'
import { setUsers } from '@store/slices/liveUsers'
import { useAppDispatch } from '@store/typedHooks'
import { useEffect } from 'react'

const { db, child, ref, set, update, onDisconnect, onValue } = fb

export function useConnectLiveUsers() {
  const dispatch = useAppDispatch()

  const { user: currentUser } = useAuth0()
  const isFirebaseAuthenticated = useFirebaseAuthStatus()
  const liveUsersRef = ref(db, 'liveUsers')
  const currentUserRef = child(liveUsersRef, `${currentUser?.sub}`)

  useEffect(() => {
    if (!isFirebaseAuthenticated) {
      return
    }

    const listener = () => {
      update(currentUserRef, { path: window.location.hash })
    }

    if (currentUser) {
      update(currentUserRef, {
        name: currentUser.name,
        nickname: currentUser.nickname,
      })
      listener()
      onDisconnect(currentUserRef).remove()
      window.addEventListener('hashchange', listener)
    }

    return () => {
      window.removeEventListener('hashchange', listener)
      set(currentUserRef, null)
    }
  }, [currentUser, isFirebaseAuthenticated])

  useEffect(() => {
    if (!isFirebaseAuthenticated) {
      return
    }

    const unsubscribe = onValue(liveUsersRef, snap => {
      const data = snap.val()
      if (data) {
        delete data[currentUser?.sub || '']
        const users = Object.values(data)
        dispatch(setUsers(users))
      }
    })

    return () => unsubscribe()
  }, [currentUser, isFirebaseAuthenticated])
}
