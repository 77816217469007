import { TemplateType } from '@generated/graphql'
import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../index'

const templatesList = (state: RootState) => state.templates.list
const allTemplatesList = (state: RootState) => state.templates.allList

export const templatesSelector = createSelector(
  [templatesList],
  templatesList => {
    return templatesList
  },
)

export const localTemplatesSelector = createSelector(
  state => ({
    list: state.templates.list,
    currentNetworkId: state.networks.currentNetworkId,
  }),
  ({ list, currentNetworkId }) => {
    return list.filter(t =>
      currentNetworkId ? t.network?.id === currentNetworkId : !t.network,
    )
  },
)

export const allTemplatesSelector = createSelector(
  [allTemplatesList],
  allTemplatesList => {
    return allTemplatesList
  },
)

export const templateByTypeSelector = createSelector(
  [
    state => ({
      list: state.templates.list,
      currentNetworkId: state.networks.currentNetworkId,
    }),
    (templatesList, type: TemplateType) => type,
    (templatesList, type: TemplateType, subtype?: string) => subtype,
  ],
  ({ list, currentNetworkId }, type, subtype) =>
    subtype
      ? list.find(
          template =>
            template.type === type &&
            template.subtype === subtype &&
            (template.network?.id === currentNetworkId || !template.network),
        )
      : list.find(
          template =>
            template.type === type &&
            (template.network?.id === currentNetworkId || !template.network),
        ),
)

export const templatesByTypeSelector = createSelector(
  [templatesList, (templatesList, type: TemplateType) => type],
  (templatesList, type) =>
    templatesList.filter(template => template.type === type),
)

export const templatesLoadingSelector = createSelector(
  [templatesList, (state: RootState) => state.templates.loading],
  (templatesList, loading) => loading,
)

export const templatesPersistedLoadingSelector = createSelector(
  [templatesList, (state: RootState) => state.templates.loading],
  (templatesList, loading) => !templatesList?.length && loading,
)

export const templatesSmartLoadingSelector = createSelector(
  [
    templatesList,
    (state: RootState, networkId?: string) => {
      return {
        loading: state.templates.loading,
        networkId,
      }
    },
  ],
  (templatesList, { loading, networkId }) => {
    return (
      !templatesList?.some(template =>
        networkId ? template.network?.id === networkId : !template.network?.id,
      ) && loading
    )
  },
)
