import { UserOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { APP_MENU_SELECTED_KEY } from '@constants/storageHelper'
import StorageHelper from '@modules/storage'
import { Avatar, Dropdown } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './style.module.scss'

const ProfileMenu = () => {
  const { user, logout } = useAuth0()

  const onLogout = () => {
    StorageHelper.setItem(APP_MENU_SELECTED_KEY, null)
    logout({ returnTo: window.location.origin })
  }

  const menuItems = [
    {
      key: 'hello',
      label: (
        <span className="font-weight-medium">
          <FormattedMessage id="topBar.profileMenu.hello" />,{' '}
          {user?.name || 'Anonymous'}
        </span>
      ),
    },
    { type: 'divider' },
    {
      key: 'email-phone',
      label: (
        <div>
          <span className="font-weight-medium">
            <FormattedMessage id="topBar.profileMenu.email" />:{' '}
          </span>
          {user?.email}
          <br />
          <span className="font-weight-medium">
            <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
          </span>
          {user?.phone || '-'}
        </div>
      ),
    },
    { type: 'divider' },
    {
      key: 'profile',
      label: (
        <div>
          <i className={`${styles.menuIcon} icmn-user`} />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </div>
      ),
    },
    { type: 'divider' },
    {
      key: 'logout',
      label: (
        <div onClick={onLogout}>
          <i className={`${styles.menuIcon} icmn-exit`} />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </div>
      ),
    },
  ]

  const menu = { selectable: false, items: menuItems }
  return (
    <Dropdown menu={menu} trigger={['hover']} placement="bottomRight">
      <div className={styles.hoverable}>
        <Avatar
          icon={<UserOutlined />}
          style={{ backgroundColor: '#001529' }}
        />
      </div>
    </Dropdown>
  )
}
export default ProfileMenu
