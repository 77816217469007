import { DEFAULT_LANGUAGE } from '@constants'

import { Site, SitesWhere } from './types'

export function sameLanguageSiteId(sites: Site[], languageTag: string) {
  return (
    sites.find(site => site.language.languageTag === languageTag)?.id ||
    sites.find(
      site => site.language.languageTag === DEFAULT_LANGUAGE.languageTag,
    )?.id ||
    null
  )
}

export function getSiteLanguageTag(sites: Site[], siteId: string) {
  return sites.find(site => site.id === siteId)?.language.languageTag
}

export const getSitesWhere: SitesWhere = verticalId => ({
  verticalId: {
    equals: verticalId,
  },
})
