function setObject(key: string, value) {
  setItem(key, JSON.stringify(value))
}

function getObject(key: string) {
  const value = getItem(key)
  if (!value) {
    return
  }

  try {
    return JSON.parse(value)
  } catch (error) {
    console.error('Parsing from localStorage failed: ', error)
  }
}

function getItem(key: string) {
  return localStorage.getItem(key)
}

function setItem(key: string, value) {
  return localStorage.setItem(key, value)
}

function remove(key: string) {
  localStorage.removeItem(key)
}

const StorageHelper = {
  getItem,
  setItem,
  getObject,
  setObject,
  remove,
}

export default StorageHelper
