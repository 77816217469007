import { DEFAULT_LANGUAGE } from '@constants'
import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../index'

const languagesList = (state: RootState) => state.languages.list

export const languagesListSelector = createSelector(
  [languagesList],
  languagesList => languagesList,
)

export const defaultLanguageIdSelector = createSelector(
  [languagesList],
  languagesList =>
    languagesList.find(
      language => language.languageTag === DEFAULT_LANGUAGE.languageTag,
    )?.id || DEFAULT_LANGUAGE.id,
)

export const languageIdByLanguageTagSelector = createSelector(
  [languagesList, (languagesList, languageTag: string) => languageTag],
  (languagesList, languageTag) =>
    languagesList.find(language => language.languageTag === languageTag)?.id ||
    DEFAULT_LANGUAGE.id,
)
