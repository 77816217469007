import { useAuth0 } from '@auth0/auth0-react'
import { useAllTemplatesLazyQuery } from '@generated/graphql'
import { currentNetworkIdSelector } from '@store/slices/networks/selectors'
import {
  setAllTemplates,
  setAllTemplatesLoading,
} from '@store/slices/templates'
import { templatesLoadingSelector } from '@store/slices/templates/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { Modal } from 'antd'
import { useEffect } from 'react'

export function useFetchAllTemplates() {
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()
  const networkId = useAppSelector(currentNetworkIdSelector)
  const sitesLoading = useAppSelector(state => state.sites.loading)
  const templatesLoading = useAppSelector(templatesLoadingSelector)

  const [queryAllTemplates] = useAllTemplatesLazyQuery({
    onCompleted: ({ templates }) => {
      dispatch(setAllTemplates(templates))
    },
    onError: error => {
      Modal.error({
        title: 'All Templates failed to load',
        content: 'Please reload page',
      })
      console.error('Unable to load templates', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated || sitesLoading || templatesLoading) {
      return
    }

    dispatch(setAllTemplatesLoading(true))
    queryAllTemplates({
      variables: {
        where: {
          networkId: {
            equals: networkId || null,
          },
        },
      },
    })
  }, [
    networkId,
    sitesLoading,
    isAuthenticated,
    templatesLoading,
    queryAllTemplates,
    dispatch,
  ])
}
