import { Result } from 'antd'
import React, { FC } from 'react'

import { IFeedbackResult } from './types'

const FeedbackResult: FC<IFeedbackResult> = ({ title, subTitle }) => {
  return (
    <Result className="mt-5" status="404" title={title} subTitle={subTitle} />
  )
}
export default FeedbackResult
