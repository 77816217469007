import { IS_NEW_PROPERTY_KEY } from '@constants/fieldKeys'

import { CountsWhere } from './types'

export const createCountsWhere: CountsWhere = networkId => ({
  AND: [
    {
      networkId: {
        equals: networkId,
      },
    },
    {
      properties: {
        some: {
          key: {
            equals: IS_NEW_PROPERTY_KEY,
          },
          data: {
            contains: 'true',
          },
        },
      },
    },
  ],
})
