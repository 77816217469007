import Localization from '@components/LayoutComponents/Localization'
import ApolloProviderConfigured from '@modules/apollo'
import Auth0ProviderConfigured from '@modules/auth0'
import Router from '@pages/router'
import store, { persistor } from '@store'
import InitialStoreComponent from '@store/components/InitialStoreComponent'
import { ConfigProvider } from 'antd'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import ErrorBoundary from './components/ErrorBoundary'

function App() {
  return (
    <ErrorBoundary>
      <Auth0ProviderConfigured>
        <ApolloProviderConfigured>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <InitialStoreComponent>
                <Localization>
                  <ConfigProvider
                    theme={{
                      token: {
                        fontFamily: 'Roboto, -apple-system, myriad-pro',
                      },
                    }}>
                    <Router />
                  </ConfigProvider>
                </Localization>
              </InitialStoreComponent>
            </PersistGate>
          </ReduxProvider>
        </ApolloProviderConfigured>
      </Auth0ProviderConfigured>
    </ErrorBoundary>
  )
}

export default App
