import 'reset-css'
import './global.scss'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://d875a7fe4c4242209e6bbcc2a161296e@o573423.ingest.sentry.io/5723938',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  })
}

if (process.env.REACT_APP_WEBSPELLCHECKER_ID) {
  window.WEBSPELLCHECKER_CONFIG = {
    autoSearch: true,
    autoDestroy: true,
    serviceId: process.env.REACT_APP_WEBSPELLCHECKER_ID,
    autocorrect: true,
    autocomplete: true,
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
