import { DEFAULT_SELECTOR_STORE_PARAM_KEY } from '@constants'
import { SelectorConfigBeginState } from '@pages/router/components/TopSelectorSet/hooks/useTopSelector'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Network, NetworksState } from './types'

const initialState: NetworksState = {
  list: [],
  currentNetworkId: DEFAULT_SELECTOR_STORE_PARAM_KEY,
  loading: false,
  selectorConfig: {},
}

const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {
    setNetworks(state, action: PayloadAction<Network[]>) {
      state.list = action.payload
      state.loading = false
    },
    setCurrentNetwork(state, action: PayloadAction<string>) {
      state.currentNetworkId = action.payload
    },
    resetCurrentNetwork(state) {
      state.currentNetworkId = initialState.currentNetworkId
    },
    setNetworksLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setNetworksSelectorConfig(
      state,
      action: PayloadAction<SelectorConfigBeginState>,
    ) {
      state.selectorConfig = action.payload
    },
  },
})

export default networksSlice.reducer

export const {
  setNetworks,
  resetCurrentNetwork,
  setCurrentNetwork,
  setNetworksLoading,
  setNetworksSelectorConfig,
} = networksSlice.actions
