import logoInvertedSource from '@assets/images/logo-ua.png'
import { LinkWithQuery } from '@components/LinkWithQuery'
import React from 'react'

import styles from './style.module.scss'

const Logo = () => {
  return (
    <div>
      <LinkWithQuery to="/" className={styles.logo}>
        <img src={logoInvertedSource} alt="logo" />
        <span className={styles.company}>Deployr</span>
      </LinkWithQuery>
    </div>
  )
}

export default Logo
