import { RocketOutlined } from '@ant-design/icons'
import { LinkWithQuery } from '@components/LinkWithQuery'
import { useCheckPermissions } from '@components/Permissions/useCheckPermissions'
import { DEFAULT_LANGUAGE } from '@constants'
import { NAME_KEY } from '@constants/fieldKeys'
import { getStringFromContent } from '@modules/content'
import { currentNetworkIdSelector } from '@store/slices/networks/selectors'
import {
  currentVerticalIdSelector,
  currentVerticalSelector,
  verticalsListSelector,
  verticalsSelectorConfigSelector,
} from '@store/slices/verticals/selectors'
import { useAppSelector } from '@store/typedHooks'
import { permissions } from '@wdnsolutions/auth-helpers'
import { Dropdown } from 'antd'
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import useTopSelectorChange from '../hooks/useChangeTopSelector'
import { IMenuItem } from '../SiteSelect'
import dropdownStyles from '../style.module.scss'

const VerticalSelect = () => {
  const currentVertical = useAppSelector(currentVerticalSelector)
  const selectorConfig = useAppSelector(verticalsSelectorConfigSelector)
  const currentNetworkId = useAppSelector(currentNetworkIdSelector)

  const disabledDropdown = selectorConfig.disabled || !currentNetworkId

  const menu = useRenderMenu()

  return (
    <Dropdown
      disabled={disabledDropdown}
      menu={menu}
      trigger={['click']}
      placement="bottomLeft">
      <div
        className={classNames(
          dropdownStyles.dropdownButton,
          disabledDropdown && dropdownStyles.disabled,
        )}>
        {renderVertical(currentVertical)}
      </div>
    </Dropdown>
  )
}

export default memo(VerticalSelect)

function renderVertical(currentVertical) {
  if (!currentVertical) {
    return (
      <span className="text-uppercase text-white">
        <FormattedMessage id="topBar.allVerticals" />
      </span>
    )
  }

  return (
    <span className="badge badge-pill badge-success d-inline ml-3 small">
      {getStringFromContent(NAME_KEY, {
        content: currentVertical?.content,
        language: DEFAULT_LANGUAGE.languageTag,
      }) || currentVertical?.id}
    </span>
  )
}

function useRenderMenu() {
  const currentVerticalId = useAppSelector(currentVerticalIdSelector)
  const selectorConfig = useAppSelector(verticalsSelectorConfigSelector)
  const verticals = useAppSelector(verticalsListSelector)
  const isAddingVerticalAllowed = useCheckPermissions([
    permissions.vertical.create,
  ])
  const { onTopSelectorChange } = useTopSelectorChange('vertical')

  const menuItems = useMemo(() => {
    const menuItems: IMenuItem[] = [
      {
        key: 'all',
        label: 'All verticals',
        disabled: selectorConfig?.autoSet,
      },
      {
        key: 'divider1',
        type: 'divider',
      },
    ]

    verticals?.forEach(vertical => {
      menuItems.push({
        key: vertical.id,
        label:
          getStringFromContent(NAME_KEY, {
            content: vertical?.content,
            language: DEFAULT_LANGUAGE.languageTag,
          }) || vertical?.id,
      })
    })

    if (verticals?.length) {
      menuItems.push({
        key: 'divider2',
        type: 'divider',
      })
    }

    if (isAddingVerticalAllowed) {
      menuItems.push({
        key: 'add',
        label: (
          <LinkWithQuery to="/verticals/add">
            <RocketOutlined className="mr-2" /> Add vertical
          </LinkWithQuery>
        ),
      })
    }

    return menuItems
  }, [verticals, isAddingVerticalAllowed, selectorConfig?.autoSet])

  return {
    selectable: false,
    selectedKeys: [currentVerticalId || 'all'],
    onClick: onTopSelectorChange,
    items: menuItems,
  }
}
