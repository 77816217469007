import { useAuth0 } from '@auth0/auth0-react'
import { SortOrder, useVerticalsSlimLazyQuery } from '@generated/graphql'
import { defaultLanguageIdSelector } from '@store/slices/languages/selectors'
import { setVerticals } from '@store/slices/verticals'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { useCallback, useEffect } from 'react'

export function useFetchVerticals(refetchOnly = false) {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const defaultLanguageId = useAppSelector(defaultLanguageIdSelector)

  const [queryVerticals, { refetch, called }] = useVerticalsSlimLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ verticals }) => {
      dispatch(setVerticals(verticals))
    },
    onError: error => {
      console.error('Unable to load networks', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated || !defaultLanguageId || refetchOnly) {
      return
    }

    queryVerticals({
      variables: getQueryVariables(defaultLanguageId),
    })
  }, [isAuthenticated, defaultLanguageId, refetchOnly, dispatch])

  const fetchVerticals = useCallback(async () => {
    if (called) {
      refetch(getQueryVariables(defaultLanguageId))
    } else {
      queryVerticals({
        variables: getQueryVariables(defaultLanguageId),
      })
    }
  }, [dispatch, refetch, defaultLanguageId])

  if (refetchOnly) {
    return fetchVerticals
  }
}

const getQueryVariables = defaultLanguageId => {
  return {
    defaultLanguageId: defaultLanguageId,
    orderBy: { createdAt: SortOrder.Asc },
  }
}
