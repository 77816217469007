import React, { FC } from 'react'

import styles from '../../style.module.scss'
import { IMenuItemLabel } from './types'

const MenuItemLabel: FC<IMenuItemLabel> = ({ icon, title }) => {
  return (
    <span>
      <span className={`${icon} ${styles.icon}`} />
      <span className={styles.title}>{title}</span>
    </span>
  )
}

export default MenuItemLabel
