import { TeamOutlined } from '@ant-design/icons'
import { LinkWithQuery } from '@components/LinkWithQuery'
import { liveUsersSelector } from '@store/slices/liveUsers/selectors'
import { useAppSelector } from '@store/typedHooks'
import { Avatar, Badge, List, Popover } from 'antd'
import React from 'react'

import styles from './style.module.scss'

const LiveUsers = () => {
  const users = useAppSelector(liveUsersSelector)

  return (
    <Popover
      title={<h3>Active Users</h3>}
      placement="bottomRight"
      content={
        <List
          size="small"
          itemLayout="horizontal"
          dataSource={users}
          renderItem={user => {
            const initials = user?.name
              ? getInitials(user?.name)
              : user?.nickname
            const userPath = user?.path?.replace(/#/, '') ?? '/'

            return (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <LinkWithQuery to={userPath}>
                      <Avatar>{initials}</Avatar>
                    </LinkWithQuery>
                  }
                />
                <LinkWithQuery to={userPath}>{user?.name}</LinkWithQuery>
              </List.Item>
            )
          }}
        />
      }>
      <div className={styles.hoverable}>
        <Badge
          count={users.length}
          showZero
          offset={[-4, 6]}
          className={styles.badgeCount}>
          <Avatar className={styles.menuIcon}>
            <TeamOutlined />
          </Avatar>
        </Badge>
      </div>
    </Popover>
  )
}

export default LiveUsers

function getInitials(string) {
  const names = (string || '').split(' ')
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}
