import { useAuth0 } from '@auth0/auth0-react'
import Loader from '@components/LayoutComponents/Loader'
import { firebaseSignIn } from '@modules/firebase'
import React, { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useLocation } from 'react-router-dom'

import LoginLayout from './Login'
import MainLayout from './Main'
import PublicLayout from './Public'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}
const getLayout = pathname => {
  if (pathname === '/') {
    return 'public'
  }
  if (/^\/user(?=\/|$)/i.test(pathname)) {
    return 'login'
  }
  return 'main'
}

const IndexLayout = ({ children }) => {
  const location = useLocation()
  const Container = Layouts[getLayout(location.pathname)]

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
  const isLoginLayout = getLayout(location.pathname) === 'login'

  useEffect(() => {
    if (isAuthenticated) {
      firebaseSignIn(getAccessTokenSilently)
    }
  }, [isAuthenticated, getAccessTokenSilently])

  const BootstrappedLayout = () => {
    if (isLoading && !isAuthenticated) {
      return <Loader />
    }

    // Redirect to login page if current is not login page and user not authorized
    if (!isLoginLayout && !isAuthenticated) {
      return <Redirect to="/user/login" />
    }

    // Redirect to main dashboard when user on login page and authorized
    if (isLoginLayout && isAuthenticated) {
      return <Redirect to="/verticals/list" />
    }

    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="Deployr | %s" title="Admin panel" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default IndexLayout
