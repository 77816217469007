import NewTag from '@components/NewTag'
import { countsSelector } from '@store/slices/counts/selectors'
import { useAppSelector } from '@store/typedHooks'
import { Badge } from 'antd'
import React, { FC } from 'react'

import styles from '../../style.module.scss'
import { IMenuItemLabelWithCount } from './types'

const MenuItemLabelWithCount: FC<IMenuItemLabelWithCount> = ({
  countName,
  icon,
  title,
  isMenuCollapsed,
}) => {
  const countsState = useAppSelector(countsSelector)

  const currentCount = countsState[countName]

  if (isMenuCollapsed) {
    return (
      <span>
        <Badge count={currentCount} color="#52C41A" offset={[-7, 10]}>
          <span className={`${icon} ${styles.icon}`} />
          <span className={styles.title}>{title}</span>
        </Badge>
      </span>
    )
  }

  return (
    <span>
      <span className={`${icon} ${styles.icon}`} />
      <span className={styles.title}>{title}</span>
      {currentCount ? (
        <NewTag text={`+${currentCount}`} color="#52C41A" />
      ) : null}
    </span>
  )
}

export default MenuItemLabelWithCount
