import { useAuth0 } from '@auth0/auth0-react'
import { useAllTaxonomyItemsLazyQuery } from '@generated/graphql'
import { defaultLanguageIdSelector } from '@store/slices/languages/selectors'
import {
  setAllTaxonomyItems,
  setAllTaxonomyItemsError,
} from '@store/slices/taxonomies'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { useCallback, useEffect } from 'react'

export function useFetchAllTaxonomyItems(refetchOnly: boolean = false) {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const defaultLanguageId = useAppSelector(defaultLanguageIdSelector)

  const [query, { refetch, called }] = useAllTaxonomyItemsLazyQuery({
    notifyOnNetworkStatusChange: true,
    // TODO: return cache-and-network fetchPolicy if there are some issues with taxonomy display
    onCompleted: ({ taxonomyItems }) => {
      dispatch(setAllTaxonomyItems(taxonomyItems))
    },
    onError: error => {
      console.error('Unable to load taxonomies', error)
      dispatch(setAllTaxonomyItemsError(error.message))
    },
  })

  useEffect(() => {
    if (!isAuthenticated || !defaultLanguageId || refetchOnly) {
      return
    }

    query({
      variables: getQueryVariables(defaultLanguageId),
    })
  }, [dispatch, query, refetchOnly, isAuthenticated, defaultLanguageId])

  const _refetch = useCallback(() => {
    if (called) {
      refetch(getQueryVariables(defaultLanguageId))
    } else {
      query({
        variables: getQueryVariables(defaultLanguageId),
      })
    }
  }, [refetch, defaultLanguageId, called])

  if (refetchOnly) {
    return {
      refetch: _refetch,
    }
  }
}

const getQueryVariables = defaultLanguageId => {
  return {
    languageId: defaultLanguageId,
  }
}
