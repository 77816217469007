import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../index'

const menuData = (state: RootState) => state.menu

export const menuLeftDataSelector = createSelector(
  [menuData],
  menuData => menuData.menuLeftData,
)

export const menuTopDataSelector = createSelector(
  [menuData],
  menuData => menuData.menuTopData,
)
